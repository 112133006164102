import React, { Component } from 'react';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import Cookies from 'js-cookie'
import Home from './components/home';
import Terms from './components/pages/terms';
import Privacy from './components/pages/privacy';
import Login from './components/auth/login';
import NoMatch from './components/auth/no_match';
import Signup from './components/auth/signup';
import ClaimBYO from './components/claim/claimBYO';
import MyProfile from './components/profile/myProfile';
import NotAllowed from './components/auth/not_allowed';
import ProviderHandler, { ContextHandler } from "./global/context";
import BYOform from './components/byo/byo';
import EditProfile from './components/profile/edit-profile'
import EmailVerify from './global/email-verify'
import UnverifiedEmail from './global/unverified-email'
import ChangePassword from './components/profile/change-password'
import ByoDetail from './components/byo/byoView';
import ForgotPassword from './global/forgot-password'
import ResetPassword from './global/reset-password'
import ClaimByoDetail from './components/byo/claim-byo-detail'
export default class Routes extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        
        return (
            <BrowserRouter>
                {/* 
                1. Do not assign same route as DefaultUrl inside component it'll create unlimited redirects
                2. Use either AuthUrl or DefaultUrl with 'Default' Component
            */}
                <ProviderHandler>
                    <ContextHandler.Consumer>
                        {(context) => (
                            <Switch>
                                {/* {console.error(context)} */}
                                <AuthRoute exact path="/login" {...context} Default={Login} AuthUrl={"/"} />
                                <AuthRoute exact path="/signup" {...context} Default={Signup} AuthUrl={"/"} />
                                <AuthRoute exact path="/byo/:type/:id?" {...context} Authenticated={BYOform} Default={Login} />
                                 <AuthRoute exact path="/byo-detail/:id" {...context} Authenticated={ByoDetail} Default={Login} /> 
                                 <AuthRoute exact path="/claim-byo-detail/:id" {...context} Authenticated={ClaimByoDetail} Default={Login} /> 
                                <AuthRoute exact path="/claim/:id" {...context} Authenticated={ClaimBYO} Default={Login} />
                                <AuthRoute exact path="/profile" {...context} Authenticated={MyProfile} Default={NotAllowed} />
                                <AuthRoute exact path="/edit/profile" {...context} Authenticated={EditProfile} Default={NotAllowed} />
                                <AuthRoute exact path="/change-password" {...context} Authenticated={ChangePassword} Default={NotAllowed} />
                                <Route exact path="/" render={(props) => <Home {...props} {...context}/>} />
                                <Route exact path="/terms-and-conditions" render={(props) => <Terms {...props} {...context} />} />
                                <Route exact path="/email-verify/:token?" render={(props) => <EmailVerify {...props} {...context} />} />
                                <Route exact path="/resend-verification-link" render={(props) => <UnverifiedEmail {...props} {...context} />} />
                                <Route exact path="/reset-password/:token" render={(props) => <ResetPassword {...props} {...context} />} />
                                <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} {...context} />} />
                                <Route exact path="/privacy-policy" render={(props) => <Privacy {...props} {...context} />} />
                                <Route component={NoMatch} />
                            </Switch>
                        )}
                    </ContextHandler.Consumer>
                </ProviderHandler>
            </BrowserRouter>)
    }
}


class AuthRoute extends Component {
    render() {
        // console.log(this.props)
        const { Authenticated, Default, AuthUrl, DefaultUrl, ...props } = this.props
        const isAuth = Cookies.get('token') ? true : false
        return (
            <Route
                {...props}
                render={(props) =>
                    (AuthUrl || DefaultUrl) ?
                        (isAuth ?
                            <Redirect to={AuthUrl} />
                            :
                            DefaultUrl ? <Redirect to={DefaultUrl} /> : <Default {...props} {...this.props} />
                        )
                        :
                        (isAuth ? this.props.profile_data ? !this.props.profile_data.is_email_verified ?  <Redirect to={"/resend-verification-link"} /> : <Authenticated {...props} {...this.props} /> :<Authenticated {...props} {...this.props} /> : <Default authRedirect={true}  {...props} {...this.props} />)
                }
            />
        )
    }
}