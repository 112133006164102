import React, { Component } from "react";
import axios from 'axios';
import { ToastContainer } from "react-toastr";
import Cookies from 'js-cookie';
//import $ from "jquery";

// import firebase from 'firebase/app';
// import 'firebase/messaging';

import './toastr.scss';
import StringFile from './string-file';
var CryptoJS = require("crypto-js");
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
var container;
let refresh_count = 0;
// var messaging;
const Hash = CryptoJS.AES.encrypt(process.env.REACT_APP_API_SECRET_KEY + "_" + new Date().getTime(), "reactcellarmasters")
// console.error(process.env.REACT_APP_API_SECRET_KEY, Hash)
var axiosRequestCounter = 0;
export const ContextHandler = React.createContext();
export default class ProviderHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_online: true,
            server_error: false,
            profile_data: "",
            full_loader: false,
            header_state: "",
        };
    }

    componentWillMount() {
        this.handleInterceptors();
    }

    componentDidMount() {
        // console.log(process.env.REACT_APP_API_URL)
        var self = this;
        window.addEventListener('load', function (e) {
            if (navigator.onLine) {
                self.setState({ user_online: true })
            } else {
                self.setState({ user_online: false })
            }
        }, false);

        window.addEventListener('online', function (e) {
            self.setState({ user_online: true })
        }, false);

        window.addEventListener('offline', function (e) {
            self.setState({ user_online: false })
        }, false);
        if (Cookies.get('token')) {
            this.handleProfile();
        }
    }

    handleInterceptors() {
        var self = this;
        axios.interceptors.request.use(function (config) {
            let hash_time = new Date().getTime();
            // console.log((new URL(config.baseURL)).pathname)
            let url = "v1:" + hash_time + ":path=" + (new URL(config.baseURL)).pathname + '' + config.url;
            let bytes = CryptoJS.AES.decrypt(Hash, 'reactcellarmasters');
            let plaintext = bytes.toString(CryptoJS.enc.Utf8);
            let converted = CryptoJS.HmacSHA256(url, plaintext.split('_')[0])
            // console.error(url, hash_time, plaintext)

            if (config.data === undefined)
                config.data = {}
            if (Cookies.get('token'))
                config.headers = { 'Authorization': Cookies.get('token') ? `Bearer ${Cookies.get('token')}` : null, 'content-type': 'application/json', "x-timestamp": hash_time, "x-api-key": "v1=" + converted };
            if (config.options) {
                if (!config.options.loader) {
                    self.setState({ full_loader: false })
                } else if (config.options.loader === undefined) {
                    self.setState({ full_loader: true })
                }
                else {
                    self.setState({ full_loader: true })
                }
                if (config.options.auth) {
                    config.headers = { 'Authorization': `Bearer ${Cookies.get('token')}`, 'content-type': 'application/json', "x-timestamp": hash_time, "x-api-key": "v1=" + converted };
                }
                if (config.options.auth === undefined) {
                    config.headers = { 'Authorization': `Bearer ${Cookies.get('token')}`, 'content-type': 'application/json', "x-timestamp": hash_time, "x-api-key": "v1=" + converted };
                }
                else {
                    config.headers = { "x-timestamp": hash_time, "x-api-key": "v1=" + converted };
                }
            } else {
                self.setState({ full_loader: true })
                if (Cookies.get('token'))
                    config.headers = { 'Authorization': `Bearer ${Cookies.get('token')}`, 'content-type': 'application/json', "x-timestamp": hash_time, "x-api-key": "v1=" + converted };
                else {
                    config.headers = { 'content-type': 'application/json', "x-timestamp": hash_time, "x-api-key": "v1=" + converted };
                }
            }
            axiosRequestCounter++;
            return config;
        }, function (error) {
            self.setState({ full_loader: false })
            return Promise.reject(error);
        });
        axios.interceptors.response.use(function (response) {
            axiosRequestCounter--;
            if (axiosRequestCounter === 0) {
                self.setState({ full_loader: false })
            }
            if(response.data.status.code === 401){
                if(refresh_count === 0){
                    self.handleRefreshToken();
                }
                refresh_count ++;
            }
            if (response.data.status.code === 403) {
                let is_toggle_error = response.config.is_toggle_error === false ? false : true
                if (response.data.status.message && is_toggle_error) {
                    container.error(response.data.status.message);
                }
                if (response.config.is_show_error) {
                    if (response.data.status.detail) {
                        for (let key in response.data.status.detail) {
                            if (response.data.status.detail.hasOwnProperty(key)) {
                                if (document.getElementById([key] + "_error")) {
                                    document.getElementById([key] + "_error").innerHTML = response.data.status.detail[key][0];
                                }
                            }
                        }
                    }
                }
            }
            if (response.data.status.code === 409) {
                container.error('Please correct your device time!')
            }
            return response;

        }, function (error) {
            axiosRequestCounter--;
            if (axiosRequestCounter === 0) {
                self.setState({ full_loader: false })
            }
            //console.error("aaaaaa", JSON.parse(JSON.stringify(error)), error.response)
            var error_response = JSON.parse(JSON.stringify(error));
            if (error_response.response) {
                container.error(error_response.response.data.message)
            }
            else if (!error_response.response) {

                //  self.handleSignOut(false);
            }
            // Do something with response error
            return Promise.reject(error);
        });
    }

    handleSignOut(message = true) {
        if (message) {
            if (window.confirm('Are you sure you want to log out ?')) {
                Cookies.remove('token');
                Cookies.remove('refresh_token');
                localStorage.clear();
                window.open('/', "_self");
            }
        }
        else {
            Cookies.remove('token');
            Cookies.remove('refresh_token');
            localStorage.clear();
            window.open('/', "_self");
        }

    }

    handleRefreshToken(){
        let formData = new FormData();
        formData.append('client_id',process.env.REACT_APP_CID)
        formData.append('client_secret',process.env.REACT_APP_CS)
        formData.append('grant_type','refresh_token')
        formData.append('refresh_token',Cookies.get('refresh_token'));
        axios.post('login/',formData,{options:{auth:false}}).then(res=>{
            if(res.data.status.code === 200){
                Cookies.set('token', res.data.data.access_token, { expires: 7 })
                Cookies.set('refresh_token', res.data.data.refresh_token, { expires: 7 })
                this.handleProfile()
            }
            if(res.data.status.code === 401){
                this.handleSignOut(false);
            }
        })
    }

    handleProfile(profile_data, api_call = true) {
        if (api_call) {
            axios.get(`profile/`).then(res => {
                if (res.data.status.code === 200) {
                    this.setState({ profile_data: res.data.data }, () => {
                    })
                }
            })
        } else {
            this.setState({ profile_data: profile_data }, () => {
            })
        }
    }

    handleSearch(header_state) {
        this.setState({
            header_state: header_state
        })
    }

    handleLoader(is_loader = true) {
        this.setState({ full_loader: is_loader })
    }

    stringFile(key, toggle = true, error = true) {
        if (toggle) {
            if (error) {
                container.error(StringFile[key])
            } else {
                container.success(StringFile[key])
            }
        }
        return StringFile[key]
    }

    handleformvalidation(form_id) {
        // console.log('form valid', form_id, $("form#" + form_id + " input[type=text]"))

        // // if (form_id !== "faq_form") {
        //   $("form#" + form_id + " input[type=text]").each(function () {
        //     console.log('id', this.id, this.id.indexOf("react-select"), $(this).val())
        //     if ((form_id === 'step_first_form' && this.id.indexOf("react-select") === -1)) {
        //       let value = $(this).val();
        //       console.log('id', value)
        //       let trim_value = value.trim();
        //       $(this).val(trim_value);
        //     }
        //     else if (this.id.indexOf("react-select") === -1) {
        //       // 
        //       let value = $('#' + this.id).val();
        //       console.log('id dc', value)
        //       let trim_value = value.trim();
        //       $('#' + this.id).val(trim_value);
        //     }
        //   });
        // // }
        // $('#' + form_id).validator('validate'); // Execute the validation
        // if ($('#' + form_id).data('bs.validator').validate().hasErrors()) {
        //   return false;
        // } else {
        //   return true;
        // }
    }

    render() {
        return (<React.Fragment>
            {this.state.user_online &&
                <ContextHandler.Provider
                    value={{
                        state: { ...this.state },
                        axios: axios,
                        profile_data: this.state.profile_data,
                        signOut: (message) => this.handleSignOut(message),
                        formvalidate: (form_id) => this.handleformvalidation(form_id),
                        clearToaster: () => container.clear(),
                        handleLoader: (loader) => this.handleLoader(loader),
                        handleSearch: (header_state) => this.handleSearch(header_state),
                        handleProfile: (profile_data, api_call) => this.handleProfile(profile_data, api_call),
                        success: (text) => container.success(text),
                        error: (text) => container.error(text),
                        stringFile: (key, toggle, error) => this.stringFile(key, toggle, error),
                    }}
                >
                    {this.props.children}
                    <ToastContainer
                        preventDuplicates={true}
                        ref={ref => container = ref}
                        className="toast-top-right"
                    />

                    <div className={this.state.full_loader ? "full_loader" : ""}>
                    </div>


                </ContextHandler.Provider>
            }


        </React.Fragment>);
    }
}