import React, { Component } from "react";
import Layout from "../layout";
import "../components/auth/auth.scss";
import { validation } from './custom_validator';
import Helmet from 'react-helmet'
export default class ForgotPassword extends Component {
    state = {
        email: "",
        is_send: false,
    }

    componentDidMount() {
        (function () {
            window.scrollTo(0, 0)
        })()
    }

    handleLogin = (e) => {
        e.preventDefault();
        if (validation('login_form')) {
            this.props.axios.get(`password/`, { params: { email: this.state.email } }).then(res => {
                if (res.data.status.code === 200) {
                    this.setState({ is_send: true })
                }
            })
        }
    }

    render() {
        return (
            <Layout {...this.props} layout="shrink">
                <Helmet>
                    <title>Forgot Password</title>
                </Helmet>
                <div className="auth_container">
                    <div className="login_header">
                        <div className="container">
                            <h1 className="heading_with_back">
                                <span className="back_button" onClick={() => { this.props.history.goBack() }}><img src="/images/back.svg" alt="Go back to homepage" /></span>  Forgot Password
              </h1>
                        </div>
                    </div>
                    <div className="auth_body">
                        <div className="container">
                            {!this.state.is_send &&
                                <form className="login_form" id="login_form" onSubmit={this.handleLogin} noValidate={true}>
                                    <div className="form_group custom_error">
                                        <label htmlFor="email">Email Address</label>
                                        <input autoComplete="new-email" id="email" type="email" onChange={(e) => this.setState({ email: e.target.value })} required />
                                        <div id="email_error" className="has-error"></div>
                                    </div>
                                    <div className="form_group">
                                        <button className="login_button">Send Link</button>
                                    </div>
                                </form>
                            }
                            {this.state.is_send &&
                                <div className="success_submit_box">
                                    {/* <img src="/images/check.svg" alt="" /> */}
                                    <>
                                        <h2>Link Send Successfully</h2>
                                        <p>A link to reset password has been sent to your email</p>
                                        <button className="claimed_button" onClick={(e) => this.props.history.push('/login')} style={{ width: 264, margin: 5 }}>Back to Login</button></>

                                </div>

                            }
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
