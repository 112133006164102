import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './header.scss'
import $ from 'jquery';
export default class Header extends Component {
    state = {
        search: "",
        zip_input: "",
        search_result: [],
    }


    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
        if (this.props.state !== undefined) {
            if (this.props.state.header_state) {
                this.setState(this.props.state.header_state);
            }
        }
    }
    listenScrollEvent = (e) => {
        let header_height = document.getElementById("main_header")
        if (header_height) {
            if (header_height.clientHeight) {
                if (window.scrollY > (header_height.clientHeight - 50)) {
                    header_height.classList.add("scrolling")
                } else {
                    header_height.classList.remove("scrolling")
                }
            }
        }
    }
    handleSearch = (e) => {
        e.preventDefault();
        if ($.trim(this.state.search) || $.trim(this.state.zip_input)) {
            this.props.axios.get(`byo/`, { params: { name: this.state.search, zipcode: this.state.zip_input } }).then(res => {
                if (res.data.status.code === 200) {
                    this.setState({ search_result: res.data }, () => {
                        this.props.handleSearch(this.state);
                    })
                }
            })
        } else {
            this.props.error("Search by Postcode or BYO's name")
        }
    }
    render() {
        return (
            <header id="main_header">
                <div className="container">
                    <div>
                        <Link to='/'><img className="site_logo" src="/images/logo.svg" alt="BYO cellarmasters logo" /></Link>
                    </div>
                    <form className="search_container" onSubmit={this.handleSearch}>
                        <input placeholder="Postcode"
                            className="padding_align zip_input"
                            type="number"
                            onChange={(e) => { this.setState({ zip_input: e.target.value }) }}
                            value={this.state.zip_input}
                        />
                        <input placeholder="Enter BYO name . ."
                            className="padding_align byo_name_input"
                            type="search"
                            onChange={(e) => { this.setState({ search: e.target.value }) }}
                            value={this.state.search}
                        />
                        <button className="search_byo_btn" type="submit" >
                            <i className="material-icons">search</i>
                        </button>
                    </form>
                </div>
            </header>
        )
    }
}