import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './footer.scss'
export default class Footer extends Component{
    render(){
        return(
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">Copyright © 2019 Cellarmasters.</div>
                        <div className="col-12 col-md-6 desk_right">
                            <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}