import React, { Component } from "react";
import Layout from '../layout'
import Helmet from 'react-helmet';
export default class UnverifiedEmail extends Component {

    componentDidMount() {
        (function () {
            window.scrollTo(0, 0)
        })()
    }

    componentDidUpdate() {
        if (this.props.profile_data) {
            if (this.props.profile_data.is_email_verified) {
                this.props.history.push('/');
            }
        }
    }

    handleResendLink = () => {
        this.props.axios.get('email-verify/').then(res => {
            if (res.data.status.code === 200) {
                this.props.success(res.data.status.message);
            }
        })
    }

    render() {
        return (
            <Layout {...this.props} layout="shrink">
                 <Helmet>
                    <title>Verify Email</title>
                </Helmet>
                <div className="static_pages">
                    <div className="container" style={{ textAlign: "center" }}>
                        {/* <h1 className="page_title">Registered Successfully</h1>
                        <p >You haven't verify your email yet, So please verify your email to access all the functionality of the BYO finder app.
                        </p> */}
                        <div className="success_submit_box">
                            {/* <img src="/images/check.svg" alt="" /> */}
                            <>
                                <h2>Verify Your Email</h2>
                                <p>Please verify your email to access all the functionality of the BYO finder app.
                                </p>
                                <button className="claimed_button" onClick={this.handleResendLink} style={{ width: 264, margin: 5 }}>Resend Verification Link</button>
                                <button title="Logout" className="claimed_button" onClick={this.props.signOut} style={{ width: 264, margin: 5 }}>Logout</button>
                            </>

                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
