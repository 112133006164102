import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import Slider from "react-slick";
import Layout from '../../layout';
import './byo.scss';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Helmet from 'react-helmet';
const queryString = require('query-string');
export default class ByoDetail extends Component {
    state = {
        byo_detail: "",
        byo_images: [],
        next_page: "",
    }

    componentDidMount() {
        this.getByoDetail();
        this.getImages();
        (function () {
            window.scrollTo(0, 0)
        })()
    }

    getByoDetail() {
        this.props.axios.get(`byo/${this.props.match.params.id}/`).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ byo_detail: res.data.data })
            }
        })
    }

    getImages() {
        this.props.axios.get(`byo-image/`, { params: { b_y_o_id: this.props.match.params.id } }).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ byo_images: res.data.data, next_page: res.data.next })
            }
        })
    }


    handleMoreImages = () => {
        const parsed = queryString.parseUrl(this.state.next_page);
        this.props.axios.get(`byo-image/`, { params: parsed.query }).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ byo_images: [...this.state.byo_images, ...res.data.data], next_page: res.data.next })
            }
        })
    }

    handleClaim = (i) => {
        window.localStorage.setItem('byo', JSON.stringify({ name: i.name, address: i.address, phone: i.phone }));
        this.props.history.push(`/claim/${i.id}`);
      }


    render() {
        console.log(this.props.history)
        var settings = {
            dots: false,
            infinite: false,
            // centerMode: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (oldIndex, newIndex) => {
                if (newIndex === this.state.byo_images.length && this.state.next_page) {
                    console.log(oldIndex, newIndex)
                    this.handleMoreImages();
                }
            }
            // variableWidth: true,
        };
        const { byo_detail, byo_images } = this.state
        return (
            <Layout {...this.props} layout="shrink">
                <Helmet>
                    <title>{byo_detail ? `${byo_detail.name}` : ""}</title>
                    <meta name="description" content={byo_detail ? `${byo_detail.description}` : ""}/>
                </Helmet>
                <div className="homepage">
                    <div className="container">
                        <div className="row">
                            {Cookies.get('token') ?
                                <Fragment>
                                    <div className="col-12 col-md-6">
                                        <div className="wrap_welcome">
                                            <h2>Hi, <span>{this.props.profile_data.first_name ? this.props.profile_data.first_name : ""} {this.props.profile_data.last_name ? this.props.profile_data.last_name : ""}</span></h2>
                                            <div>
                                                <Link className="manage_account_link" to="/profile#byo">
                                                    Manage Account
                                                    <img src="/images/next.svg" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {/* <div className="wrap_welcome"> */}
                                        {/* <h3 style={{visibility:"hidden"}} >Logout</h3> */}
                                        <button className="logout_btn" to='/login' onClick={(e) => this.props.signOut(true)}>
                                            Logout
                                            <img src="/images/logout.svg" alt="" />
                                        </button>
                                        {/* </div> */}
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <div className="col-12 col-md-6">
                                        <div className="wrap_welcome">
                                            <h2>Welcome to Cellarmasters <div>
                                                <span>BYO Finder</span>
                                            </div></h2>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 wrap_right">
                                        <div className="wrap_welcome">
                                            <h3>Manage your BYO's</h3>
                                            <Link className="sign_in_btn" to='/login' >Sign In</Link> <span>|</span> <Link to='/signup' className="sign_in_btn">Register</Link>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
                {byo_detail &&
                    <div className="byo-detail">
                        <div className="byo-main-detail">
                            <div className="container">
                                <h5 className="inner_back" style={{ textAlign: "initial" }}>
                                    <img onClick={(e) => this.props.history.push('/profile#byo')} src="/images/back.svg" alt="" />
                                    Go back
                                </h5>
                                <div className="full_byo_container">
                                    <div className="full_det_header">
                                        <img src={byo_detail.logo} alt="" />
                                        <h1>{byo_detail.name}</h1>
                                        {(byo_detail.is_claimed && byo_detail.owner) &&
                                            <button className="edit" onClick={(e) => this.props.history.push(`/byo/edit/${this.props.match.params.id}`)}><i className="material-icons">edit</i>Edit BYO</button>
                                        }
                                        {(byo_detail.is_claimed && !byo_detail.owner) &&
                                            <button title="Already Claimed" style={{ color: "#fff", backgroundColor: "#d7be7f" }} className="claimed_button" disabled>Claimed</button>
                                        }
                                        {!byo_detail.is_claimed &&
                                            <button className="claim_now" onClick={() => this.handleClaim(byo_detail)}>Claim</button>
                                        }
                                        {/* <button className="claim_now">Claim BYO</button> */}
                                    </div>
                                    <div className="fit_image_crop">
                                        <Slider {...settings}>
                                            <div><img src={byo_detail.cover_image} alt="" /></div>
                                            {byo_images.map(function (el, index) {
                                                return (
                                                    <div key={`other_image_${index}`}><img alt="byo" src={el.image} /></div>
                                                )
                                            })}
                                        </Slider>
                                        {/* <img src={byo_detail.cover_image} alt="" /> */}
                                    </div>
                                    {/* <div className="other_byo_images">
                                        {this.state.next_page &&
                                            <button className="view_more_imgs" onClick={this.handleMoreImages.bind(this)}>View More +</button>
                                        }
                                    </div> */}
                                    <div className="description_byo_det">
                                        <h3>About</h3>
                                        <p>{byo_detail.description ? byo_detail.description : "No Information Available"}</p>
                                    </div>
                                    <table className="byo_details_table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>Phone</b>
                                                    <div className="value_fields">
                                                        {byo_detail.phone ? byo_detail.phone : "No Information Available"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Email</b>
                                                    <div className="value_fields">
                                                        {byo_detail.email ? byo_detail.email : "No Information Available"}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Country</b>
                                                    <div className="value_fields">
                                                        {byo_detail.country}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>State</b>
                                                    <div className="value_fields">
                                                        {byo_detail.state}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>City</b>
                                                    <div className="value_fields">
                                                        {byo_detail.city}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Postcode</b>
                                                    <div className="value_fields">
                                                        {byo_detail.zipcode}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Address</b>
                                                    <div className="value_fields">
                                                        {byo_detail.address}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Website</b>
                                                    <div className="value_fields">
                                                        {byo_detail.website ?
                                                            <React.Fragment>
                                                                <a href={byo_detail.website}>View Website</a>
                                                            </React.Fragment>
                                                            :
                                                            "No Information Available"
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Sitting Capacity</b>
                                                    <div className="value_fields">
                                                        {byo_detail.sitting_capacity ? byo_detail.sitting_capacity : "No Information Available"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Entry Fee</b>
                                                    <div className="value_fields">
                                                        {byo_detail.entry_fee ? `$ ${byo_detail.entry_fee}` : "No Information Available"}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Corkage Fee</b>
                                                    <div className="value_fields">
                                                        {byo_detail.corkage_fee ? `$ ${byo_detail.corkage_fee}` : "No Information Available"}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <b>Cuisines</b>
                                                    <div className="value_fields">
                                                        <ul>
                                                            {byo_detail.cuisines.map(function (el, index) {
                                                                return <li className="image" key={`cuisine_${el.id}`}> <img src={el.image} alt="" /> {el.name}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <b>Facilities</b>
                                                    <div className="value_fields">
                                                        <ul>
                                                            {byo_detail.facilities.map(function (el, index) {
                                                                return <li className="r_size" key={`facilities_${el.id}`}>{el.name}</li>
                                                            })}
                                                            {byo_detail.facilities.length === 0 &&
                                                                <p>No Information Available</p>
                                                            }
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <b>Payments Accepted</b>
                                                    <div className="value_fields">
                                                        <ul>
                                                            {byo_detail.payment_types.map(function (el, index) {
                                                                return <li className="r_size" key={`payment_types${el.id}`}>{el.name}</li>
                                                            })}
                                                            {byo_detail.payment_types.length === 0 &&
                                                                <p>No Information Available</p>
                                                            }
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <b>Timings</b>
                                                    <div className="value_fields">
                                                        <ul>

                                                            {byo_detail.byotiming_set.length > 0 &&
                                                                <React.Fragment>
                                                                    {byo_detail.byotiming_set.map((i, index) => {
                                                                        var days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
                                                                        return (<li key={index} className="byo_timing">
                                                                            <span><b style={{ display: "inline" }}>{days[i.day]}</b> - </span>
                                                                            <span className="table_time">{i.from_time + " - " + i.to_time}</span>
                                                                        </li>)
                                                                    }, this)
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {byo_detail.byotiming_set.length === 0 &&
                                                                <p>No Information Available</p>
                                                            }

                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {!byo_detail &&

                    <div style={{ minHeight: "calc(100vh - 263px)" }}>
                        <p className="no_byo_found">
                            <img src="/images/search_ico.svg" alt="" />
                            No BYO found </p>
                    </div>

                }
            </Layout>
        )
    }
}