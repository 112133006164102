import React,{Component} from 'react'
import Layout from '../../layout';
export default class NoMatch extends Component{
    render(){
        return(
            <Layout {...this.props} layout="shrink">
                    <div className="container">
                <div className="not_found_page">
                    <img src="/images/problem.svg" alt=""/>
                        <h1>404 page not found</h1>
                    </div>
                </div>
            </Layout>
            )
    }
}