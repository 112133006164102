import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom'
import "./search.scss";
// import Cookies from 'js-cookie'
const queryString = require('query-string');
export default class SearchResults extends Component {

  componentDidMount() {
    // console.error(this.props)
  }

  handleMore = () => {
    const parsed = queryString.parseUrl(this.props.state.header_state.search_result.next);
    this.props.axios.get('byo/', { params: parsed.query }).then(res => {
      if (res.data.status.code === 200) {
        let result_object = {
          search: parsed.query.name,
          zip_input: parsed.query.zipcode,
          search_result: {
            count: res.data.count,
            data: [...this.props.state.header_state.search_result.data, ...res.data.data],
            next: res.data.next,
            previous: res.data.previous,
            status: { code: 200, message: null },
            total_pages: res.data.total_pages,
          }
        }
        this.props.handleSearch(result_object);
      }
    })
  }

  handleClaim = (i) => {
    window.localStorage.setItem('byo', JSON.stringify({ name: i.name, address: i.address, phone: i.phone }));
    this.props.history.push(`/claim/${i.id}`);
  }

  render() {
    const { search_result } = this.props.state.header_state
    console.log(search_result)
    return (
      <Fragment>
        {search_result &&
          <Fragment>
            <div className="search_results">
              <div className="container">
                <h3>Search Results
              {search_result.data.length > 0 &&
                    <span>{search_result ? search_result.count : 0} Matches Found</span>
                  }
                </h3>

                <div className="each_result_cont">
                  {search_result.data.length > 0 &&
                    <table>
                      {/* <thead>
                        <tr>
                          <th>BYO Title</th>
                          <th> Address</th>
                        </tr>
                      </thead> */}
                      <tbody>
                        {search_result.data.map((i) => {
                          return (<tr key={`result_list_${i.id}`}>
                            <td>
                              <h4 className="byo_name" onClick={(e) => { this.props.history.push(`/byo-detail/${i.id}`) }}>{i.name}</h4>
                              {/* <div className="byo_cuisine">Italian</div> */}
                            </td>
                            <td>
                              {/* <div className="byo_number">{i.phone}</div> */}
                              <div className="byo_address">{i.address}</div>
                            </td>
                            <td width="230">
                              {i.is_claimed ?
                                <button title="Already Claimed" className="claimed_button" disabled>Claimed</button>
                                :
                                <button onClick={() => this.handleClaim(i)} className="clain_trigger">Claim</button>
                              }
                            </td>
                          </tr>)
                        })
                        }
                      </tbody>
                    </table>
                  }
                  {search_result.data.length > 0 &&
                    <p className="display_count_results">displaying
                      {search_result ?
                        <Fragment>
                          <span> {search_result.data.length}</span> of <span> {search_result.count}</span>
                        </Fragment>
                        : "0 of 0"}
                    </p>
                  }
                  {search_result.next &&
                    <div style={{ textAlign: "center" }}>

                      <span className="see_more_results" onClick={this.handleMore}>
                        See More
                          <img src="/images/down.svg" alt="" />
                      </span>

                    </div>
                  }
                  {search_result.data.length === 0 &&
                    <p className="no_byo_found">
                      <img src="/images/search_ico.svg" alt="" />
                      No BYO found with your search keyword</p>

                  }
                </div>
              </div>
            </div>

          </Fragment>
        }
        <div className="create_byo_link">
          {search_result ?
            <p>Didn't find your BYO?</p>
            :
            null
          }
          <Link to="/byo/add" className="new_byo_link">Create New BYO</Link>
        </div>
      </Fragment>

    );
  }
}