import React, { Component } from "react";
import Layout from '../../layout'
export default class Privacy extends Component {
  state={
    text : ""
  }
  componentDidMount() {
    this.getPrivacy();
  }
  getPrivacy() {
    this.props.axios.get('privacy-policy/').then(res => {
      if (res.data.status.code === 200) {
        this.setState({text:res.data.data.text})
      }
    })
  }
  render() {
    return (
      <Layout {...this.props} layout="shrink">
        <div className="static_pages">
          <div className="container">
            <h1 className="page_title">Privacy Policy</h1>
            <div dangerouslySetInnerHTML={{ __html: this.state.text }}>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
