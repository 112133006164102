
import React, { Component } from 'react'
import $ from 'jquery'
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import './react-crop.scss'

export default class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile_picture: "",
            cover_image_data: "",
            is_cropped_disabled: true,
            cropped_img: "",
            image_object: "",
            is_image_change: false,
            modal_id: "cropModal",
            boundaries: [],
            type: "abc",
            crop: {
                aspect: 1 / 1
            },
            mandatory: false,
        };
    }
    componentDidMount() {

        this.setState({
            mandatory: this.props.mandatory,
            profile_picture: this.props.profile_picture,
            image_object: this.props.image_object,
            modal_id: this.props.modal_id ? this.props.modal_id : "cropModal",
            type: this.props.type ? this.props.type : "",
            crop: {
                aspect: this.props.aspect_ratio ? this.props.aspect_ratio : 1 / 1,
            }
        });
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            mandatory: newProps.mandatory,
            profile_picture: newProps.profile_picture,
            image_object: newProps.image_object,
            modal_id: newProps.modal_id ? newProps.modal_id : "cropModal",
            type: newProps.type ? newProps.type : "",
            crop: {
                aspect: newProps.aspect_ratio ? newProps.aspect_ratio : 1 / 1,
            }
        }, () => {
            if (newProps.image_object.length > 0) {
                this.handleprofile(newProps.image_object)
            }
        });
    }
    componentWillUnmount() {
        $('.' + this.state.modal_id).hide();
    }

    handleprofile(abc) {
        const file = abc[0];
        this.setState({ is_cropped_disabled: true, })
        var self = this;
        if ((file.type).toLowerCase() === "image/jpeg" || (file.type).toLowerCase() === "image/png" || (file.type).toLowerCase() === "image/jpg") {
            if (((file.size / 1024) / 1024).toFixed(4) < 10) {
                const reader = new FileReader();
                reader.onload = (e2) => {
                    self.setState({ cover_image_data: e2.target.result }, () => {
                        $('.' + this.state.modal_id).show();
                    })
                };
                reader.readAsDataURL(file);
            }
            else {
                this.props.error("Image size must be less than 10 MB")
            }
        }
        else {
            this.props.error("file type not supported")
        }

    }
    onImageLoaded(image) {
        this.imageRef = image;
        if (this.state.type !== "wine") {
            this.setState({
                crop: makeAspectCrop({
                    x: 0,
                    y: 0,
                    aspect: this.state.crop.aspect,
                    width: 100,
                }, image.naturalWidth / image.naturalHeight),
                image,
            });

        } else {
            this.setState({
                crop: makeAspectCrop({
                    x: 0,
                    y: 0,
                    width: 10,
                }, image.naturalWidth / image.naturalHeight),
                image,
            });
        }
    }


    onCropComplete(crop, pixelCrop) {
        // console.error(this.imageRef)
        // document.getElementById('cover_image')
        var a = this.getCroppedImg(this.imageRef, pixelCrop, "blob_profile_image")
        this.setState({ cropped_img: a, is_cropped_disabled: false }, () => {
            // this.props.onimageupload(this.state.profile_picture);
        })
    }
    getCroppedImg(image, pixelCrop, fileName) {
        this.setState({ is_image_change: true });
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);
        var boundary = [pixelCrop.x, pixelCrop.y, (pixelCrop.x + pixelCrop.width), (pixelCrop.y + pixelCrop.height)]
        this.setState({
            boundaries: boundary
        }, () => {
        })
        return canvas.toDataURL('image/png')
    }

    onCropChange(crop) {
        this.setState({ crop });
    }

    handle_crop_error() {
        this.props.error("Please Re-postion the selected area to crop the image.")
    }
    close_crop_modal() {
        this.setState({ profile_picture: this.state.cropped_img, cover_image_data: "", crop: { aspect: 16 / 9 } }, () => {
            $('.' + this.state.modal_id).hide();
            $('#user_image_12').val('');
            if (this.state.modal_id === "modalCrop") {
                $('#user_image_13').val('');
            }
            this.props.onimageupload(this.state.profile_picture, this.state.boundaries);
        })
    }
    close_crop_modal_withoutchange() {
        this.setState({ profile_picture: "", cropped_img: "", cover_image_data: "", crop: { aspect: 16 / 9 } }, () => {
            $('.' + this.state.modal_id).hide();
            $('#user_image_12').val('');
            if (this.state.modal_id === "modalCrop") {
                $('#user_image_13').val('');
            }
            this.props.onimageupload(this.state.profile_picture, this.state.boundaries);
        })
    }

    render() {

        return (
            <React.Fragment>
                <div className={this.state.modal_id} style={{ display: "none" }}>
                    <div className="cropBody">
                        <h1>Crop Image <span><i className="material-icons" onClick={this.close_crop_modal_withoutchange.bind(this)}>close</i></span></h1>
                        <div className="paddingCrop">
                            <div>
                                <ReactCrop
                                    ref={this.state.type}
                                    crop={this.state.crop}
                                    keepSelection={true}
                                    src={this.state.cover_image_data ? this.state.cover_image_data : ""}
                                    onImageLoaded={this.onImageLoaded.bind(this)}
                                    onComplete={this.onCropComplete.bind(this)}
                                    onChange={this.onCropChange.bind(this)}
                                />
                            </div>
                            <button type="button" className="cropBtn" onClick={this.state.is_cropped_disabled ? this.handle_crop_error.bind(this) : this.close_crop_modal.bind(this)}>Crop</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};