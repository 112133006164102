import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';
import Layout from "../../layout";
import "./auth.scss";
import { validation } from '../../global/custom_validator';
import Helmet from 'react-helmet'
export default class Login extends Component {
  state = {
    email: "",
    password: "",
  }

  componentDidMount() {
    (function () {
      window.scrollTo(0, 0)
    })()
  }

  handleLogin = (e) => {
    e.preventDefault();
    if (validation('login_form')) {
      let formData = new FormData();
      formData.append("username", this.state.email);
      formData.append("password", this.state.password);
      formData.append("client_id", process.env.REACT_APP_CID);
      formData.append("client_secret", process.env.REACT_APP_CS);
      formData.append("grant_type", 'password');
      this.props.axios.post('login/', formData).then(res => {
        if (res.data.status.code === 200) {
          // if (res.data.data.is_email_verified) {
          Cookies.set('token', res.data.data.access_token, { expires: 7 })
          Cookies.set('refresh_token', res.data.data.refresh_token, { expires: 7 })
          this.props.handleProfile();
          // }
        }
      })
    }
  }

  render() {
    return (
      <Layout {...this.props} layout="shrink">
        <Helmet>
          <title>Login to BYO Finder</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="auth_container">
          <div className="login_header">
            <div className="container">
              <h1 className="heading_with_back">
                <span className="back_button" onClick={() => { this.props.history.goBack() }}><img src="/images/back.svg" alt="Go back to homepage" /></span>  Login
              </h1>
            </div>
          </div>
          <div className="auth_body">
            <div className="container">
              <form className="login_form" id="login_form" onSubmit={this.handleLogin} noValidate={true}>
                {this.props.authRedirect &&
                  <p className="login_claim_message">Please login or Signup to continue ...</p>
                }
                <div className="form_group custom_error">
                  <label htmlFor="email">Email Address</label>
                  <input autoComplete="new-email" id="email" type="email" onChange={(e) => this.setState({ email: e.target.value })} required />
                  <div id="email_error" className="has-error"></div>
                </div>
                <div className="form_group custom_error">
                  <label htmlFor="password">Password</label>
                  <input
                    autoComplete="new-password"
                    id="password"
                    type="password"
                    onChange={(e) => this.setState({ password: e.target.value })}
                    // pattern="^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
                    required
                  />
                  <div id="password_error" className="has-error"></div>
                  <div className="forgot_pass_link">
                    <Link to="/forgot-password" >Forgot Password ?</Link>
                  </div>
                </div>
                <div className="form_group">
                  <button className="login_button">Login</button>
                  <div className="ask_account_exists">
                    Don't have an account?{" "}
                    {this.props.authRedirect ?
                      <Link to={`/signup?redirect_url=${window.location.href}`}>Sign Up</Link>
                      :
                      <Link to='/signup'>Sign Up</Link>
                    }
                    <br />

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
