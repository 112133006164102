import React, { Component, Fragment } from 'react'
import Layout from '../../layout';
import { Link } from 'react-router-dom'
import './profile.scss'
import dateFormat from 'dateformat'
import Helmet from 'react-helmet';
const queryString = require('query-string');
export default class MyProfile extends Component {
    state = {
        activeTab: "#byo",
        request_byo_list: "",
        byo_list: "",
        claim_byo_list: "",
    }

    componentDidMount() {
        // if (this.props.profile_data) {
        //     this.getByo();
        // }
        //console.error(this.props.location.hash, this.state.activeTab)
        if (this.props.profile_data) {
            if (this.props.location.hash === "#profile") {
                this.setState({ activeTab: "#profile" })
            } else if (this.props.location.hash === "#requested_byo") {
                this.setState({ activeTab: "#requested_byo" }, () => {
                    this.getRequestByo();
                })
            } else if (this.props.location.hash === "#claimed_requests") {
                this.setState({ activeTab: "#claimed_requests" }, () => {
                    this.getclaimByo();
                })
            }
            else if (this.props.location.hash === "#byo") {
                this.setState({ activeTab: "#byo" }, () => {
                    this.getByo();
                })
            }
            else if (this.props.location.hash === "") {
                // this.setState({ activeTab: "#byo" }, () => {
                //     this.getByo();
                // })
                this.props.history.push('/profile#byo')
            }
        } else {
            this.props.history.push('/profile#byo')
        }

    }

    componentDidUpdate(prevProps) {

        // console.log(this.props, prevProps, this.state.activeTab)
        if (!this.props.profile_data) {
            return true;
        }
        if ((prevProps.profile_data.id !== this.props.profile_data.id) || (this.props.location.hash !== prevProps.location.hash) || (prevProps.location.hash !== this.state.activeTab)) {
            if (this.props.location.hash === "#requested_byo") {
                this.setState({ activeTab: "#requested_byo" }, () => {
                    this.getRequestByo();
                })
            }
            else if (this.props.location.hash === "#claimed_requests") {
                this.setState({ activeTab: "#claimed_requests" }, () => {
                    this.getclaimByo();
                })
            }
            else if (this.props.location.hash === "#profile") {
                this.setState({ activeTab: "#profile" }, () => {
                    (function () {
                        window.scrollTo(0, 0);
                    })()
                })
            } else {
                if (this.props.location.hash === "#byo") {
                    this.setState({ activeTab: "#byo" }, () => {
                        this.getByo();
                    })
                }
                //  else {
                //     this.props.history.push('/profile#byo')
                // }

            }
        }
    }

    getByo() {
        this.props.axios.get(`byo/`, { params: { owner: this.props.profile_data.id } }).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ activeTab: "#byo", byo_list: res.data }, () => {
                    (function () {
                        window.scrollTo(0, 0);
                    })()
                })
            }
        })
    }

    handleNotificationStatus = () => {
        var formData = new FormData();
        formData.append('notification_setting', !this.props.profile_data.notification_setting);
        this.props.axios.put(`users/${this.props.profile_data.id}/`, formData).then(res => {
            if (res.data.status.code === 200) {
                this.props.handleProfile(res.data.data, false)
            }
        })
    }
    getRequestByo() {
        this.props.axios.get(`byo-request/`).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ activeTab: "#requested_byo", request_byo_list: res.data }, () => {
                    (function () {
                        window.scrollTo(0, 0);
                    })()
                })
            }
        })
    }

    getclaimByo() {
        this.props.axios.get(`byo-claim/`).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ activeTab: "#claimed_requests", claim_byo_list: res.data }, () => {
                    (function () {
                        window.scrollTo(0, 0);
                    })()
                })
            }
        })
    }

    handleMore = () => {
        const parsed = queryString.parseUrl(this.state.byo_list.next);
        this.props.axios.get('byo/', { params: parsed.query }).then(res => {
            let byo_list = this.state.byo_list;
            byo_list.count = res.data.count;
            byo_list.data = [...this.state.byo_list.data, ...res.data.data];
            byo_list.next = res.data.next;
            byo_list.previous = res.data.previous;
            byo_list.total_pages = res.data.total_pages;
            this.setState({ activeTab: "#byo", byo_list: byo_list })
        })
    }

    handleMoreRequestByo = () => {
        const parsed = queryString.parseUrl(this.state.request_byo_list.next);
        this.props.axios.get('byo-request/', { params: parsed.query }).then(res => {
            let request_byo_list = this.state.request_byo_list;
            request_byo_list.count = res.data.count;
            request_byo_list.data = [...this.state.request_byo_list.data, ...res.data.data];
            request_byo_list.next = res.data.next;
            request_byo_list.previous = res.data.previous;
            request_byo_list.total_pages = res.data.total_pages;
            this.setState({ activeTab: "#requested_byo", request_byo_list: request_byo_list })
        })
    }

    handleMoreClaimRequest() {
        const parsed = queryString.parseUrl(this.state.claim_byo_list.next);
        this.props.axios.get('byo-claim/', { params: parsed.query }).then(res => {
            let claim_byo_list = this.state.claim_byo_list;
            claim_byo_list.count = res.data.count;
            claim_byo_list.data = [...this.state.claim_byo_list.data, ...res.data.data];
            claim_byo_list.next = res.data.next;
            claim_byo_list.previous = res.data.previous;
            claim_byo_list.total_pages = res.data.total_pages;
            this.setState({ activeTab: "#claimed_requests", claim_byo_list: claim_byo_list })
        })

    }

    render() {
        return (
            <Layout {...this.props} layout="shrink">
                <Helmet>
                    <title>Manage Account</title>
                </Helmet>
                <div className="user_profile_page">
                    <div className="profile_head">
                        <div className="container">
                            <div className="profile_tabs">
                                {/* <div to="" className={this.state.activeTab === "byo" ? "tab active" : "tab"}>My BYO's</div>
                                <div onClick={this.getRequestByo.bind(this)} className={this.state.activeTab === "requested_byo" ? "tab active" : "tab"}>Requested BYO's</div>
                                <div onClick={() => { this.setState({ activeTab: "profile" }) }} className={this.state.activeTab === "profile" ? "tab active" : "tab"}>Manage Profile</div> */}
                                <div className="inner_tabs_scroll">
                                    <Link to="/profile#byo" className={this.state.activeTab === "#byo" ? "tab active" : "tab"}>My BYO's</Link>
                                    <Link to="/profile#requested_byo" className={this.state.activeTab === "#requested_byo" ? "tab active" : "tab"}>Requested BYO's</Link>
                                    <Link to="/profile#profile" className={this.state.activeTab === "#profile" ? "tab active" : "tab"}>Manage Profile</Link>
                                </div>
                                <button className="logout_link_profile logout_btn" onClick={(e) => this.props.signOut(true)}>Logout<img src="/images/logout.svg" alt="" /></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ marginTop: 0 }} className="container">
                            <div className="each_result_cont">
                                {this.state.activeTab === "#byo" &&
                                    <>
                                        <h5 className="inner_back">
                                            <img onClick={(e) => this.props.history.push('/')} src="/images/back.svg" alt="" />
                                            My BYO's

                                            <Link className="byo_requests" to="/profile#claimed_requests">Claim requests</Link>
                                        </h5>
                                        {this.state.byo_list &&
                                            <table>
                                                <tbody>
                                                    {this.state.byo_list.data.map((i) => {
                                                        return (<tr key={`byo_${i.id}`}>
                                                            <td>
                                                                <h4 className="byo_name verified" onClick={() => { this.props.history.push(`/byo-detail/${i.id}`) }}>{i.name}</h4>
                                                                {/* <div className="byo_cuisine">Italian</div> */}
                                                            </td>
                                                            <td>
                                                                <div className="byo_number">{i.phone}</div>
                                                                <div className="byo_address">{i.address}</div>
                                                            </td>
                                                            <td width="230">
                                                                <Link to={`/byo/edit/${i.id}`} className="claim_trigger_yellow"><img src="/images/edit.svg" alt="" />Edit</Link>
                                                            </td>
                                                        </tr>)
                                                    })}
                                                    {this.state.byo_list.data.length === 0 &&
                                                        <tr>
                                                            <td style={{ textAlign: "center" }}>No BYO found!</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>

                                        }
                                        {this.state.byo_list.next &&
                                            <div style={{ textAlign: "center" }}>

                                                <span className="see_more_results" onClick={this.handleMore}>
                                                    See More
                                                    <img src="/images/down.svg" alt="" />
                                                </span>

                                            </div>
                                        }

                                    </>
                                }

                                {this.state.activeTab === "#claimed_requests" &&
                                    <>
                                        <h5 className="inner_back">
                                            <img onClick={this.props.history.goBack} src="/images/back.svg" alt="" />
                                            Claim Requested BYO's

                                            {/* <Link className="byo_requests" to ="/profile#claimed_requests">Claim requests</Link> */}
                                        </h5>
                                        {this.state.claim_byo_list &&
                                            <table>
                                                <tbody>
                                                    {this.state.claim_byo_list.data.map((i) => {
                                                        return (<tr key={`claim_byo_list_${i.id}`} >
                                                            <td>
                                                                <h4 className="byo_name" onClick={() => { this.props.history.push(`/claim-byo-detail/${i.id}`) }}>{i.b_y_o.name}</h4>
                                                                {/* <div className="byo_cuisine">Italian</div> */}
                                                            </td>
                                                            <td>
                                                                <div className="byo_number">{i.b_y_o.phone}</div>
                                                                <div className="byo_address">{i.b_y_o.address}</div>
                                                            </td>
                                                            <td width="230">
                                                                <button title="This BYO is under review" className="claim_trigger_black">{i.accepted ? "Accepted" : "Under Review"}</button>
                                                            </td>
                                                        </tr>)
                                                    })}
                                                    {this.state.claim_byo_list.data.length === 0 &&
                                                        <tr>
                                                            <td style={{ textAlign: "center" }}>
                                                                No Requested BYO found!
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                        {this.state.claim_byo_list.next &&
                                            <div style={{ textAlign: "center" }}>

                                                <span className="see_more_results" onClick={this.handleMoreClaimRequest}>
                                                    See More
                                                    <img src="/images/down.svg" alt="" />
                                                </span>

                                            </div>
                                        }

                                    </>
                                }

                                {this.state.activeTab === "#requested_byo" &&
                                    <>
                                        <h5 className="inner_back">
                                            <img onClick={this.props.history.goBack} src="/images/back.svg" alt="" />
                                            Requested BYO's

                                            {/* <Link className="byo_requests" to ="/profile#claimed_requests">Claim requests</Link> */}
                                        </h5>
                                        {this.state.request_byo_list &&
                                            <table>
                                                <tbody>
                                                    {this.state.request_byo_list.data.map((i) => {
                                                        return (<tr key={`request_byo_list_${i.id}`}>
                                                            <td>
                                                                <h4 className="byo_name disabled_link">{i.name}</h4>
                                                                {/* <div className="byo_cuisine">Italian</div> */}
                                                            </td>
                                                            <td>
                                                                <div className="byo_number">{i.phone}</div>
                                                                <div className="byo_address">{i.address}</div>
                                                            </td>
                                                            <td width="230">
                                                                <button title="This BYO is under review" className="claim_trigger_black">Under Review</button>
                                                            </td>
                                                        </tr>)
                                                    })}
                                                    {this.state.request_byo_list.data.length === 0 &&
                                                        <tr>
                                                            <td style={{ textAlign: "center" }}>
                                                                No Requested BYO found!
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                        {this.state.request_byo_list.next &&
                                            <div style={{ textAlign: "center" }}>

                                                <span className="see_more_results" onClick={this.handleMoreRequestByo}>
                                                    See More
                                                    <img src="/images/down.svg" alt="" />
                                                </span>

                                            </div>
                                        }

                                    </>
                                }
                                {(this.state.activeTab === "#profile" && this.props.profile_data) &&

                                    <Fragment>
                                        <h5 className="inner_back">
                                            <img onClick={() => this.props.history.push('/profile#byo')} src="/images/back.svg" alt="" />
                                            My Profile

                                            {/* <Link className="byo_requests" to ="/profile#claimed_requests">Claim requests</Link> */}
                                        </h5>
                                        <table className="user_profile_table">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">
                                                        <h3> Name</h3>
                                                        <div className="profile_value">{this.props.profile_data.first_name} {this.props.profile_data.last_name}</div>

                                                    </td>
                                                    <td colSpan="2">
                                                        <h3> Email</h3>

                                                        <div className="profile_value">{this.props.profile_data.email}</div>
                                                    </td>
                                                    {/* <td>
                                                        <h3>Notifications</h3>
                                                        <div className="custom_toggle">
                                                            <input id="google_places" type="checkbox" onChange={this.handleNotificationStatus} checked={this.props.profile_data.notification_setting} />
                                                            <label htmlFor="google_places"></label>
                                                            <label className="make_toggle" htmlFor="google_places"><span></span></label>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <h3>Phone</h3>
                                                        <div className="profile_value">{this.props.profile_data.phone ? this.props.profile_data.phone : "Not Available"}</div>
                                                    </td>
                                                    <td colSpan="2">
                                                        <h3>Birth Date</h3>
                                                        <div className="profile_value">{this.props.profile_data.d_o_b ? dateFormat(this.props.profile_data.d_o_b, 'd mmm yyyy') : "Not Available"}</div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="profile_buttons">
                                            <button className="profile_primary" onClick={(e) => this.props.history.push("/change-password")}>Change Password</button>
                                            <button className="profile_secondary" onClick={(e) => this.props.history.push("/edit/profile")}> <img src="/images/edit.svg" alt="" /> Edit</button>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <button className="logout_link_mobile logout_btn" onClick={(e) => this.props.signOut(true)}>Logout</button>
                </div>
            </Layout>
        )
    }
}