import React, { Component } from "react";
import Layout from "../layout";
import "../components/auth/auth.scss";
import { validation } from './custom_validator';
import Helmet from 'react-helmet'

export default class ResetPassword extends Component {
    state = {
        password: "",
        // confirm_password: "",
        current_password: "",
        password_visible: false,
    }

    componentDidMount() {
        (function () {
            window.scrollTo(0, 0)
        })()
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        if (validation('change_password_form')) {
            // if (this.state.password !== this.state.confirm_password) {
            //     document.getElementById('confirm_password_error').innerHTML = "password and Confirm password is not same!"
            //     return true;
            // }
            // if (this.state.current_password === this.state.password) {
            //     this.props.error("New Password and Current Password Can't be same");
            //     return true;
            // }
            let formData = new FormData();

            formData.append("password", this.state.password);
            formData.append("token", this.props.match.params.token);


            this.props.axios.post('password/', formData).then(res => {
                if (res.data.status.code === 200) {
                    this.props.success(res.data.status.message)
                    this.props.history.push('/login');
                } else if (res.data.status.code === 403) {
                    if (res.data.status.detail) {
                        for (let key in res.data.status.detail) {
                            if (res.data.status.detail.hasOwnProperty(key)) {
                                if (document.getElementById([key] + "_error")) {
                                    document.getElementById([key] + "_error").innerHTML = res.data.status.detail[key][0];
                                } else {
                                    this.props.error(`${key} : ${res.data.status.detail[key][0]}`);
                                }
                            }
                        }
                    }
                }
            })
        }
    }

    render() {
        return (
            <Layout {...this.props} layout="shrink">
                <Helmet>
                    <title>Reset Password</title>
                </Helmet>
                <div className="auth_container">
                    <div className="login_header">
                        <div className="container">
                            <h1 className="heading_with_back">
                                {/* <span className="back_button" onClick={() => { this.props.history.goBack() }}>
                                    <img src="/images/back.svg" alt="Go back to homepage" />
                                </span>{" "} */}
                                Reset Password
                            </h1>
                        </div>
                    </div>
                    <div className="auth_body">
                        <div className="container">
                            <form onSubmit={this.handleSubmitForm} id="change_password_form" noValidate={true} className="signup_form">
                                <div className="row">
                                    {/* <div className="col-12 col-md-6 custom_error">
                                        <label htmlFor="current_password">Current Password</label>
                                        <input
                                            autoComplete="new-current_password"
                                            id="current_password"
                                            type="password"
                                            onChange={(e) => this.setState({ current_password: e.target.value })}
                                            pattern="^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
                                            value={this.state.current_password}
                                            required
                                        />
                                        <div id="current_password_error" className="has-error"></div>

                                    </div> */}
                                    <div className="col-12 col-md-12 custom_error" style={{ position: "relative" }}>
                                        <label htmlFor="password">Password</label>
                                        <i className="material-icons" style={{ position: "absolute", top: "43px", right: "20px", cursor: "pointer" }} onClick={(e) => { this.setState({ password_visible: !this.state.password_visible }) }}>remove_red_eye</i>

                                        <input
                                            autoComplete="new-password"
                                            id="password"
                                            type={!this.state.password_visible ? "password" : "text"}
                                            onChange={(e) => this.setState({ password: e.target.value })}
                                            data-error={this.state.password ? "Please provide valid password that is at least 8 characters, upper and lowercase letters, 1 number and 1 special character" : "Password is required"}
                                            pattern="^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
                                            value={this.state.password}
                                            required
                                        />
                                        <div id="password_error" className="has-error"></div>

                                    </div>
                                    {/* <div className="col-12 col-md-6 custom_error">
                                        <label htmlFor="confirm_password">Confirm Password</label>
                                        <input id="confirm_password" type="password" onChange={(e) => this.setState({ confirm_password: e.target.value })} value={this.state.confirm_password} pattern="^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$" datamatch="password" datamatcherror="Confirm password data and password is different!" required />
                                        <div id="confirm_password_error" className="has-error"></div>
                                    </div> */}
                                </div>

                                <div className="form_group">
                                    <button className="login_button">Set Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
