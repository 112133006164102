import React, { Component } from "react";
import Layout from "../../layout";
import 'react-dates/initialize';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
// import { Link } from "react-router-dom";
import { validation } from '../../global/custom_validator';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Helmet from 'react-helmet';
var dateFormat = require('dateformat');
export default class EditProfile extends Component {
    state = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        focused: false,
        d_o_b: null,
    }

    componentDidMount() {
        this.onFocusChange = this.onFocusChange.bind(this);
        this.setState({
            firstName: this.props.profile_data.first_name,
            lastName: this.props.profile_data.last_name,
            phone: this.props.profile_data.phone,
            email: this.props.profile_data.email,
            d_o_b: this.props.profile_data.d_o_b ? moment(this.props.profile_data.d_o_b) : null
        }, () => {
            (function () {
                window.scrollTo(0, 0)
            })()
        })



    }

    componentDidUpdate(prevProps) {
        if ((prevProps.profile_data.id !== this.props.profile_data.id) && !this.state.email) {
            this.setState({
                firstName: this.props.profile_data.first_name,
                lastName: this.props.profile_data.last_name,
                phone: this.props.profile_data.phone,
                email: this.props.profile_data.email,
                d_o_b: this.props.profile_data.d_o_b ? moment(this.props.profile_data.d_o_b) : null
            })
        }
    }
    onFocusChange({ focused }) {
        this.setState({ focused });
    }
    onDateChange(date) {
        if (date) {
            if (this.isDate18orMoreYearsOld(date.toDate().getDate(), date.toDate().getMonth(), date.toDate().getFullYear())) {
                this.setState({ d_o_b: date });
            } else {
                this.props.error('Please confirm you are over 18 years old');
            }
        } else {
            this.setState({ d_o_b: null });
        }
    }
    handleSubmitForm = (e) => {
        e.preventDefault();
        if (validation('update_profile_form')) {

            if (this.state.phone) {
                if (!isValidPhoneNumber(this.state.phone)) {
                    document.getElementById('phone_error').innerHTML = "Invalid Number!"
                    return true;
                }
            }
            let rowData = {
                "first_name": this.state.firstName,
                "last_name": this.state.lastName,
                "d_o_b": this.state.d_o_b ? dateFormat(this.state.d_o_b, "yyyy-mm-dd") : null,
                "phone": this.state.phone,
            }
            if (this.props.profile_data.email !== this.state.email) {
                rowData.email = this.state.email;
            }

            this.props.axios.put(`users/${this.props.profile_data.id}/`, rowData, { is_show_error: true }).then(res => {
                if (res.data.status.code === 200) {
                    (function () {
                        window.scrollTo(0, 0)
                    })()
                    this.props.handleProfile(res.data.data, false)
                    this.props.success(res.data.status.message)
                    this.props.history.push('/profile#profile');

                }
            })

        }
    }

    isDate18orMoreYearsOld(day, month, year) {
        return new Date(year + 18, month - 1, day) <= new Date();
    }

    returnYears = () => {
        let years = []
        for (let i = moment().year() - 100; i <= moment().year() + 5; i++) {
            years.push(<option key={i} value={i}>{i}</option>);
        }
        return years;
    }
    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
                <select
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                >
                    {moment.months().map((label, value) => (
                        <option key={value} value={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div>
                <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                    {this.returnYears()}
                </select>
            </div>
        </div>

    render() {
        const { focused, d_o_b } = this.state;
        return (
            <Layout {...this.props} layout="shrink">
                <Helmet>
                    <title>Edit Profile</title>
                   
                </Helmet>
                <div className="auth_container">
                    <div className="login_header">
                        <div className="container">
                            <h1 className="heading_with_back">
                                <span className="back_button" onClick={() => { this.props.history.goBack() }}>
                                    <img src="/images/back.svg" alt="Go back to homepage" />
                                </span>{" "}
                                Edit Profile
              </h1>
                        </div>
                    </div>
                    <div className="auth_body">
                        <div className="container">
                            <form onSubmit={this.handleSubmitForm} id="update_profile_form" noValidate={true} className="signup_form">
                                <div className="row">
                                    <div className="col-12 col-md-6 custom_error">
                                        <label htmlFor="first_name">First Name</label>
                                        <input id="first_name" type="text" onChange={(e) => this.setState({ firstName: e.target.value })} value={this.state.firstName} required />
                                        <div id="first_name_error" className="has-error"></div>
                                    </div>
                                    <div className="col-12 col-md-6 custom_error">
                                        <label htmlFor="last_name">Last Name</label>
                                        <input id="last_name" type="text" onChange={(e) => this.setState({ lastName: e.target.value })} value={this.state.lastName} required />
                                        <div id="last_name_error" className="has-error"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-12 custom_error">
                                        <label htmlFor="email">Email Address</label>
                                        <input autoComplete="new-email" id="email" type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} required />
                                        <div id="email_error" className="has-error"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 custom_error">
                                        <label htmlFor="d_o_b">Birth Date</label>
                                        {/* <input id="d_o_b" type="date" onChange={(e) => this.setState({ d_o_b: e.target.value })} value={this.state.d_o_b} /> */}
                                        <SingleDatePicker
                                            date={d_o_b}
                                            isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                                            focused={focused}
                                            onFocusChange={this.onFocusChange.bind(this)}
                                            onDateChange={this.onDateChange.bind(this)}
                                            numberOfMonths={1}
                                            displayFormat='DD-MM-YYYY'
                                            renderMonthElement={this.renderMonthElement}
                                        />
                                        <div id="d_o_b_error" className="has-error"></div>
                                    </div>
                                    <div className="col-12 col-md-6 custom_error" style={{ position: "relative" }}>
                                        <label htmlFor="phone">Phone Number</label>
                                        {/* <input id="phone" type="number" onChange={(e) => this.setState({ phone: e.target.value })} required /> */}
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            value={this.state.phone}
                                            country="AU"
                                            id="phone"
                                            onChange={phone => this.setState({ phone })}
                                        />
                                        <div id="phone_error" className="has-error"></div>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <button className="login_button">Save Changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
