import React, { Component } from "react";
import "./layout.scss";
import Header from "./header";
import Footer from "./footer";
import Cookies from 'js-cookie'

export default class Layout extends Component {
  state = {
    searchQuery: '',
  }
  handleLogout = () => {
    Cookies.remove('token')
    Cookies.remove('refresh_token');
    window.open('/', '_self')
  }
  render() {
    return (
      <div className={this.props.layout ? `layout ${this.props.layout}` : "layout"}>
        <Header
          {...this.props}

        />
        {this.props.children} 
        <Footer />
      </div>
    );
  }
}
