export default {
    "Afghanistan": [
      "Badakhshan",
      "Badgis",
      "Baglan",
      "Balkh",
      "Bamiyan",
      "Farah",
      "Faryab",
      "Gawr",
      "Gazni",
      "Herat",
      "Hilmand",
      "Jawzjan",
      "Kabul",
      "Kapisa",
      "Khawst",
      "Kunar",
      "Lagman",
      "Lawghar",
      "Nangarhar",
      "Nimruz",
      "Nuristan",
      "Paktika",
      "Paktiya",
      "Parwan",
      "Qandahar",
      "Qunduz",
      "Samangan",
      "Sar-e Pul",
      "Takhar",
      "Uruzgan",
      "Wardag",
      "Zabul"
    ],
    "Albania": [
      "Berat",
      "Bulqize",
      "Delvine",
      "Devoll",
      "Dibre",
      "Durres",
      "Elbasan",
      "Fier",
      "Gjirokaster",
      "Gramsh",
      "Has",
      "Kavaje",
      "Kolonje",
      "Korce",
      "Kruje",
      "Kucove",
      "Kukes",
      "Kurbin",
      "Lezhe",
      "Librazhd",
      "Lushnje",
      "Mallakaster",
      "Malsi e Madhe",
      "Mat",
      "Mirdite",
      "Peqin",
      "Permet",
      "Pogradec",
      "Puke",
      "Sarande",
      "Shkoder",
      "Skrapar",
      "Tepelene",
      "Tirane",
      "Tropoje",
      "Vlore"
    ],
    "Algeria": [
      "\\'Ayn Daflah",
      "\\'Ayn Tamushanat",
      "Adrar",
      "Algiers",
      "Annabah",
      "Bashshar",
      "Batnah",
      "Bijayah",
      "Biskrah",
      "Blidah",
      "Buirah",
      "Bumardas",
      "Burj Bu Arririj",
      "Ghalizan",
      "Ghardayah",
      "Ilizi",
      "Jijili",
      "Jilfah",
      "Khanshalah",
      "Masilah",
      "Midyah",
      "Milah",
      "Muaskar",
      "Mustaghanam",
      "Naama",
      "Oran",
      "Ouargla",
      "Qalmah",
      "Qustantinah",
      "Sakikdah",
      "Satif",
      "Sayda\\'",
      "Sidi ban-al-\\'Abbas",
      "Suq Ahras",
      "Tamanghasat",
      "Tibazah",
      "Tibissah",
      "Tilimsan",
      "Tinduf",
      "Tisamsilt",
      "Tiyarat",
      "Tizi Wazu",
      "Umm-al-Bawaghi",
      "Wahran",
      "Warqla",
      "Wilaya d Alger",
      "Wilaya de Bejaia",
      "Wilaya de Constantine",
      "al-Aghwat",
      "al-Bayadh",
      "al-Jaza\\'ir",
      "al-Wad",
      "ash-Shalif",
      "at-Tarif"
    ],
    "American Samoa": [
      "Eastern",
      "Manu\\'a",
      "Swains Island",
      "Western"
    ],
    "Andorra": [
      "Andorra la Vella",
      "Canillo",
      "Encamp",
      "La Massana",
      "Les Escaldes",
      "Ordino",
      "Sant Julia de Loria"
    ],
    "Angola": [
      "Bengo",
      "Benguela",
      "Bie",
      "Cabinda",
      "Cunene",
      "Huambo",
      "Huila",
      "Kuando-Kubango",
      "Kwanza Norte",
      "Kwanza Sul",
      "Luanda",
      "Lunda Norte",
      "Lunda Sul",
      "Malanje",
      "Moxico",
      "Namibe",
      "Uige",
      "Zaire"
    ],
    "Anguilla": [
      "Other Provinces"
    ],
    "Antarctica": [
      "Sector claimed by Argentina/Ch",
      "Sector claimed by Argentina/UK",
      "Sector claimed by Australia",
      "Sector claimed by France",
      "Sector claimed by New Zealand",
      "Sector claimed by Norway",
      "Unclaimed Sector"
    ],
    "Antigua And Barbuda": [
      "Barbuda",
      "Saint George",
      "Saint John",
      "Saint Mary",
      "Saint Paul",
      "Saint Peter",
      "Saint Philip"
    ],
    "Argentina": [
      "Buenos Aires",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Cordoba",
      "Corrientes",
      "Distrito Federal",
      "Entre Rios",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquen",
      "Rio Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucuman"
    ],
    "Armenia": [
      "Aragatsotn",
      "Ararat",
      "Armavir",
      "Gegharkunik",
      "Kotaik",
      "Lori",
      "Shirak",
      "Stepanakert",
      "Syunik",
      "Tavush",
      "Vayots Dzor",
      "Yerevan"
    ],
    "Aruba": [
      "Aruba"
    ],
    "Australia": [
      "Auckland",
      "Australian Capital Territory",
      "Balgowlah",
      "Balmain",
      "Bankstown",
      "Baulkham Hills",
      "Bonnet Bay",
      "Camberwell",
      "Carole Park",
      "Castle Hill",
      "Caulfield",
      "Chatswood",
      "Cheltenham",
      "Cherrybrook",
      "Clayton",
      "Collingwood",
      "Frenchs Forest",
      "Hawthorn",
      "Jannnali",
      "Knoxfield",
      "Melbourne",
      "New South Wales",
      "Northern Territory",
      "Perth",
      "Queensland",
      "South Australia",
      "Tasmania",
      "Templestowe",
      "Victoria",
      "Werribee south",
      "Western Australia",
      "Wheeler"
    ],
    "Austria": [
      "Bundesland Salzburg",
      "Bundesland Steiermark",
      "Bundesland Tirol",
      "Burgenland",
      "Carinthia",
      "Karnten",
      "Liezen",
      "Lower Austria",
      "Niederosterreich",
      "Oberosterreich",
      "Salzburg",
      "Schleswig-Holstein",
      "Steiermark",
      "Styria",
      "Tirol",
      "Upper Austria",
      "Vorarlberg",
      "Wien"
    ],
    "Azerbaijan": [
      "Abseron",
      "Baki Sahari",
      "Ganca",
      "Ganja",
      "Kalbacar",
      "Lankaran",
      "Mil-Qarabax",
      "Mugan-Salyan",
      "Nagorni-Qarabax",
      "Naxcivan",
      "Priaraks",
      "Qazax",
      "Saki",
      "Sirvan",
      "Xacmaz"
    ],
    "Bahrain": [
      "\\'Isa",
      "Badiyah",
      "Hidd",
      "Jidd Hafs",
      "Mahama",
      "Manama",
      "Sitrah",
      "al-Manamah",
      "al-Muharraq",
      "ar-Rifa\\'a"
    ],
    "Bangladesh": [
      "Bagar Hat",
      "Bandarban",
      "Barguna",
      "Barisal",
      "Bhola",
      "Bogora",
      "Brahman Bariya",
      "Chandpur",
      "Chattagam",
      "Chittagong Division",
      "Chuadanga",
      "Dhaka",
      "Dinajpur",
      "Faridpur",
      "Feni",
      "Gaybanda",
      "Gazipur",
      "Gopalganj",
      "Habiganj",
      "Jaipur Hat",
      "Jamalpur",
      "Jessor",
      "Jhalakati",
      "Jhanaydah",
      "Khagrachhari",
      "Khulna",
      "Kishorganj",
      "Koks Bazar",
      "Komilla",
      "Kurigram",
      "Kushtiya",
      "Lakshmipur",
      "Lalmanir Hat",
      "Madaripur",
      "Magura",
      "Maimansingh",
      "Manikganj",
      "Maulvi Bazar",
      "Meherpur",
      "Munshiganj",
      "Naral",
      "Narayanganj",
      "Narsingdi",
      "Nator",
      "Naugaon",
      "Nawabganj",
      "Netrakona",
      "Nilphamari",
      "Noakhali",
      "Pabna",
      "Panchagarh",
      "Patuakhali",
      "Pirojpur",
      "Rajbari",
      "Rajshahi",
      "Rangamati",
      "Rangpur",
      "Satkhira",
      "Shariatpur",
      "Sherpur",
      "Silhat",
      "Sirajganj",
      "Sunamganj",
      "Tangayal",
      "Thakurgaon"
    ],
    "Barbados": [
      "Christ Church",
      "Saint Andrew",
      "Saint George",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Lucy",
      "Saint Michael",
      "Saint Peter",
      "Saint Philip",
      "Saint Thomas"
    ],
    "Belarus": [
      "Brest",
      "Homjel\\'",
      "Hrodna",
      "Mahiljow",
      "Mahilyowskaya Voblasts",
      "Minsk",
      "Minskaja Voblasts\\'",
      "Petrik",
      "Vicebsk"
    ],
    "Belgium": [
      "Antwerpen",
      "Berchem",
      "Brabant",
      "Brabant Wallon",
      "Brussel",
      "East Flanders",
      "Hainaut",
      "Liege",
      "Limburg",
      "Luxembourg",
      "Namur",
      "Ontario",
      "Oost-Vlaanderen",
      "Provincie Brabant",
      "Vlaams-Brabant",
      "Wallonne",
      "West-Vlaanderen"
    ],
    "Belize": [
      "Belize",
      "Cayo",
      "Corozal",
      "Orange Walk",
      "Stann Creek",
      "Toledo"
    ],
    "Benin": [
      "Alibori",
      "Atacora",
      "Atlantique",
      "Borgou",
      "Collines",
      "Couffo",
      "Donga",
      "Littoral",
      "Mono",
      "Oueme",
      "Plateau",
      "Zou"
    ],
    "Bermuda": [
      "Hamilton",
      "Saint George"
    ],
    "Bhutan": [
      "Bumthang",
      "Chhukha",
      "Chirang",
      "Daga",
      "Geylegphug",
      "Ha",
      "Lhuntshi",
      "Mongar",
      "Pemagatsel",
      "Punakha",
      "Rinpung",
      "Samchi",
      "Samdrup Jongkhar",
      "Shemgang",
      "Tashigang",
      "Timphu",
      "Tongsa",
      "Wangdiphodrang"
    ],
    "Bolivia": [
      "Beni",
      "Chuquisaca",
      "Cochabamba",
      "La Paz",
      "Oruro",
      "Pando",
      "Potosi",
      "Santa Cruz",
      "Tarija"
    ],
    "Bosnia and Herzegovina": [
      "Federacija Bosna i Hercegovina",
      "Republika Srpska"
    ],
    "Botswana": [
      "Central Bobonong",
      "Central Boteti",
      "Central Mahalapye",
      "Central Serowe-Palapye",
      "Central Tutume",
      "Chobe",
      "Francistown",
      "Gaborone",
      "Ghanzi",
      "Jwaneng",
      "Kgalagadi North",
      "Kgalagadi South",
      "Kgatleng",
      "Kweneng",
      "Lobatse",
      "Ngamiland",
      "Ngwaketse",
      "North East",
      "Okavango",
      "Orapa",
      "Selibe Phikwe",
      "South East",
      "Sowa"
    ],
    "Bouvet Island": [
      "Bouvet Island"
    ],
    "Brazil": [
      "Acre",
      "Alagoas",
      "Amapa",
      "Amazonas",
      "Bahia",
      "Ceara",
      "Distrito Federal",
      "Espirito Santo",
      "Estado de Sao Paulo",
      "Goias",
      "Maranhao",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Para",
      "Paraiba",
      "Parana",
      "Pernambuco",
      "Piaui",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rio de Janeiro",
      "Rondonia",
      "Roraima",
      "Santa Catarina",
      "Sao Paulo",
      "Sergipe",
      "Tocantins"
    ],
    "British Indian Ocean Territory": [
      "British Indian Ocean Territory"
    ],
    "Bulgaria": [
      "Blagoevgrad",
      "Burgas",
      "Dobrich",
      "Gabrovo",
      "Haskovo",
      "Jambol",
      "Kardzhali",
      "Kjustendil",
      "Lovech",
      "Montana",
      "Oblast Sofiya-Grad",
      "Pazardzhik",
      "Pernik",
      "Pleven",
      "Plovdiv",
      "Razgrad",
      "Ruse",
      "Shumen",
      "Silistra",
      "Sliven",
      "Smoljan",
      "Sofija grad",
      "Sofijska oblast",
      "Stara Zagora",
      "Targovishte",
      "Varna",
      "Veliko Tarnovo",
      "Vidin",
      "Vraca",
      "Yablaniza"
    ],
    "Burkina Faso": [
      "Bale",
      "Bam",
      "Bazega",
      "Bougouriba",
      "Boulgou",
      "Boulkiemde",
      "Comoe",
      "Ganzourgou",
      "Gnagna",
      "Gourma",
      "Houet",
      "Ioba",
      "Kadiogo",
      "Kenedougou",
      "Komandjari",
      "Kompienga",
      "Kossi",
      "Kouritenga",
      "Kourweogo",
      "Leraba",
      "Mouhoun",
      "Nahouri",
      "Namentenga",
      "Noumbiel",
      "Oubritenga",
      "Oudalan",
      "Passore",
      "Poni",
      "Sanguie",
      "Sanmatenga",
      "Seno",
      "Sissili",
      "Soum",
      "Sourou",
      "Tapoa",
      "Tuy",
      "Yatenga",
      "Zondoma",
      "Zoundweogo"
    ],
    "Burundi": [
      "Bubanza",
      "Bujumbura",
      "Bururi",
      "Cankuzo",
      "Cibitoke",
      "Gitega",
      "Karuzi",
      "Kayanza",
      "Kirundo",
      "Makamba",
      "Muramvya",
      "Muyinga",
      "Ngozi",
      "Rutana",
      "Ruyigi"
    ],
    "Cambodia": [
      "Banteay Mean Chey",
      "Bat Dambang",
      "Kampong Cham",
      "Kampong Chhnang",
      "Kampong Spoeu",
      "Kampong Thum",
      "Kampot",
      "Kandal",
      "Kaoh Kong",
      "Kracheh",
      "Krong Kaeb",
      "Krong Pailin",
      "Krong Preah Sihanouk",
      "Mondol Kiri",
      "Otdar Mean Chey",
      "Phnum Penh",
      "Pousat",
      "Preah Vihear",
      "Prey Veaeng",
      "Rotanak Kiri",
      "Siem Reab",
      "Stueng Traeng",
      "Svay Rieng",
      "Takaev"
    ],
    "Cameroon": [
      "Adamaoua",
      "Centre",
      "Est",
      "Littoral",
      "Nord",
      "Nord Extreme",
      "Nordouest",
      "Ouest",
      "Sud",
      "Sudouest"
    ],
    "Canada": [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon"
    ],
    "Cape Verde": [
      "Boavista",
      "Brava",
      "Fogo",
      "Maio",
      "Sal",
      "Santo Antao",
      "Sao Nicolau",
      "Sao Tiago",
      "Sao Vicente"
    ],
    "Cayman Islands": [
      "Grand Cayman"
    ],
    "Central African Republic": [
      "Bamingui-Bangoran",
      "Bangui",
      "Basse-Kotto",
      "Haut-Mbomou",
      "Haute-Kotto",
      "Kemo",
      "Lobaye",
      "Mambere-Kadei",
      "Mbomou",
      "Nana-Gribizi",
      "Nana-Mambere",
      "Ombella Mpoko",
      "Ouaka",
      "Ouham",
      "Ouham-Pende",
      "Sangha-Mbaere",
      "Vakaga"
    ],
    "Chad": [
      "Batha",
      "Biltine",
      "Bourkou-Ennedi-Tibesti",
      "Chari-Baguirmi",
      "Guera",
      "Kanem",
      "Lac",
      "Logone Occidental",
      "Logone Oriental",
      "Mayo-Kebbi",
      "Moyen-Chari",
      "Ouaddai",
      "Salamat",
      "Tandjile"
    ],
    "Chile": [
      "Aisen",
      "Antofagasta",
      "Araucania",
      "Atacama",
      "Bio Bio",
      "Coquimbo",
      "Libertador General Bernardo O\\'",
      "Los Lagos",
      "Magellanes",
      "Maule",
      "Metropolitana",
      "Metropolitana de Santiago",
      "Tarapaca",
      "Valparaiso"
    ],
    "China": [
      "Anhui",
      "Anhui Province",
      "Anhui Sheng",
      "Aomen",
      "Beijing",
      "Beijing Shi",
      "Chongqing",
      "Fujian",
      "Fujian Sheng",
      "Gansu",
      "Guangdong",
      "Guangdong Sheng",
      "Guangxi",
      "Guizhou",
      "Hainan",
      "Hebei",
      "Heilongjiang",
      "Henan",
      "Hubei",
      "Hunan",
      "Jiangsu",
      "Jiangsu Sheng",
      "Jiangxi",
      "Jilin",
      "Liaoning",
      "Liaoning Sheng",
      "Nei Monggol",
      "Ningxia Hui",
      "Qinghai",
      "Shaanxi",
      "Shandong",
      "Shandong Sheng",
      "Shanghai",
      "Shanxi",
      "Sichuan",
      "Tianjin",
      "Xianggang",
      "Xinjiang",
      "Xizang",
      "Yunnan",
      "Zhejiang",
      "Zhejiang Sheng"
    ],
    "Christmas Island": [
      "Christmas Island"
    ],
    "Cocos (Keeling) Islands": [
      "Cocos (Keeling) Islands"
    ],
    "Colombia": [
      "Amazonas",
      "Antioquia",
      "Arauca",
      "Atlantico",
      "Bogota",
      "Bolivar",
      "Boyaca",
      "Caldas",
      "Caqueta",
      "Casanare",
      "Cauca",
      "Cesar",
      "Choco",
      "Cordoba",
      "Cundinamarca",
      "Guainia",
      "Guaviare",
      "Huila",
      "La Guajira",
      "Magdalena",
      "Meta",
      "Narino",
      "Norte de Santander",
      "Putumayo",
      "Quindio",
      "Risaralda",
      "San Andres y Providencia",
      "Santander",
      "Sucre",
      "Tolima",
      "Valle del Cauca",
      "Vaupes",
      "Vichada"
    ],
    "Comoros": [
      "Mwali",
      "Njazidja",
      "Nzwani"
    ],
    "Congo": [
      "Bouenza",
      "Brazzaville",
      "Cuvette",
      "Kouilou",
      "Lekoumou",
      "Likouala",
      "Niari",
      "Plateaux",
      "Pool",
      "Sangha"
    ],
    "Cook Islands": [
      "Aitutaki",
      "Atiu",
      "Mangaia",
      "Manihiki",
      "Mauke",
      "Mitiaro",
      "Nassau",
      "Pukapuka",
      "Rakahanga",
      "Rarotonga",
      "Tongareva"
    ],
    "Costa Rica": [
      "Alajuela",
      "Cartago",
      "Guanacaste",
      "Heredia",
      "Limon",
      "Puntarenas",
      "San Jose"
    ],
    "Cuba": [
      "Camaguey",
      "Ciego de Avila",
      "Cienfuegos",
      "Ciudad de la Habana",
      "Granma",
      "Guantanamo",
      "Habana",
      "Holguin",
      "Isla de la Juventud",
      "La Habana",
      "Las Tunas",
      "Matanzas",
      "Pinar del Rio",
      "Sancti Spiritus",
      "Santiago de Cuba",
      "Villa Clara"
    ],
    "Cyprus": [
      "Government controlled area",
      "Limassol",
      "Nicosia District",
      "Paphos",
      "Turkish controlled area"
    ],
    "Czech Republic": [
      "Central Bohemian",
      "Frycovice",
      "Jihocesky Kraj",
      "Jihochesky",
      "Jihomoravsky",
      "Karlovarsky",
      "Klecany",
      "Kralovehradecky",
      "Liberecky",
      "Lipov",
      "Moravskoslezsky",
      "Olomoucky",
      "Olomoucky Kraj",
      "Pardubicky",
      "Plzensky",
      "Praha",
      "Rajhrad",
      "Smirice",
      "South Moravian",
      "Straz nad Nisou",
      "Stredochesky",
      "Unicov",
      "Ustecky",
      "Valletta",
      "Velesin",
      "Vysochina",
      "Zlinsky"
    ],
    "Denmark": [
      "Arhus",
      "Bornholm",
      "Frederiksborg",
      "Fyn",
      "Hovedstaden",
      "Kobenhavn",
      "Kobenhavns Amt",
      "Kobenhavns Kommune",
      "Nordjylland",
      "Ribe",
      "Ringkobing",
      "Roervig",
      "Roskilde",
      "Roslev",
      "Sjaelland",
      "Soeborg",
      "Sonderjylland",
      "Storstrom",
      "Syddanmark",
      "Toelloese",
      "Vejle",
      "Vestsjalland",
      "Viborg"
    ],
    "Djibouti": [
      "\\'Ali Sabih",
      "Dikhil",
      "Jibuti",
      "Tajurah",
      "Ubuk"
    ],
    "Dominica": [
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Joseph",
      "Saint Luke",
      "Saint Mark",
      "Saint Patrick",
      "Saint Paul",
      "Saint Peter"
    ],
    "Dominican Republic": [
      "Azua",
      "Bahoruco",
      "Barahona",
      "Dajabon",
      "Distrito Nacional",
      "Duarte",
      "El Seybo",
      "Elias Pina",
      "Espaillat",
      "Hato Mayor",
      "Independencia",
      "La Altagracia",
      "La Romana",
      "La Vega",
      "Maria Trinidad Sanchez",
      "Monsenor Nouel",
      "Monte Cristi",
      "Monte Plata",
      "Pedernales",
      "Peravia",
      "Puerto Plata",
      "Salcedo",
      "Samana",
      "San Cristobal",
      "San Juan",
      "San Pedro de Macoris",
      "Sanchez Ramirez",
      "Santiago",
      "Santiago Rodriguez",
      "Valverde"
    ],
    "East Timor": [
      "Aileu",
      "Ainaro",
      "Ambeno",
      "Baucau",
      "Bobonaro",
      "Cova Lima",
      "Dili",
      "Ermera",
      "Lautem",
      "Liquica",
      "Manatuto",
      "Manufahi",
      "Viqueque"
    ],
    "Ecuador": [
      "Azuay",
      "Bolivar",
      "Canar",
      "Carchi",
      "Chimborazo",
      "Cotopaxi",
      "El Oro",
      "Esmeraldas",
      "Galapagos",
      "Guayas",
      "Imbabura",
      "Loja",
      "Los Rios",
      "Manabi",
      "Morona Santiago",
      "Napo",
      "Orellana",
      "Pastaza",
      "Pichincha",
      "Sucumbios",
      "Tungurahua",
      "Zamora Chinchipe"
    ],
    "Egypt": [
      "Aswan",
      "Asyut",
      "Bani Suwayf",
      "Bur Sa\\'id",
      "Cairo",
      "Dumyat",
      "Kafr-ash-Shaykh",
      "Matruh",
      "Muhafazat ad Daqahliyah",
      "Muhafazat al Fayyum",
      "Muhafazat al Gharbiyah",
      "Muhafazat al Iskandariyah",
      "Muhafazat al Qahirah",
      "Qina",
      "Sawhaj",
      "Sina al-Janubiyah",
      "Sina ash-Shamaliyah",
      "ad-Daqahliyah",
      "al-Bahr-al-Ahmar",
      "al-Buhayrah",
      "al-Fayyum",
      "al-Gharbiyah",
      "al-Iskandariyah",
      "al-Ismailiyah",
      "al-Jizah",
      "al-Minufiyah",
      "al-Minya",
      "al-Qahira",
      "al-Qalyubiyah",
      "al-Uqsur",
      "al-Wadi al-Jadid",
      "as-Suways",
      "ash-Sharqiyah"
    ],
    "El Salvador": [
      "Ahuachapan",
      "Cabanas",
      "Chalatenango",
      "Cuscatlan",
      "La Libertad",
      "La Paz",
      "La Union",
      "Morazan",
      "San Miguel",
      "San Salvador",
      "San Vicente",
      "Santa Ana",
      "Sonsonate",
      "Usulutan"
    ],
    "Equatorial Guinea": [
      "Annobon",
      "Bioko Norte",
      "Bioko Sur",
      "Centro Sur",
      "Kie-Ntem",
      "Litoral",
      "Wele-Nzas"
    ],
    "Eritrea": [
      "Anseba",
      "Debub",
      "Debub-Keih-Bahri",
      "Gash-Barka",
      "Maekel",
      "Semien-Keih-Bahri"
    ],
    "Estonia": [
      "Harju",
      "Hiiu",
      "Ida-Viru",
      "Jarva",
      "Jogeva",
      "Laane",
      "Laane-Viru",
      "Parnu",
      "Polva",
      "Rapla",
      "Saare",
      "Tartu",
      "Valga",
      "Viljandi",
      "Voru"
    ],
    "Ethiopia": [
      "Addis Abeba",
      "Afar",
      "Amhara",
      "Benishangul",
      "Diredawa",
      "Gambella",
      "Harar",
      "Jigjiga",
      "Mekele",
      "Oromia",
      "Somali",
      "Southern",
      "Tigray"
    ],
    "Falkland Islands": [
      "Falkland Islands",
      "South Georgia"
    ],
    "Faroe Islands": [
      "Klaksvik",
      "Nor ara Eysturoy",
      "Nor oy",
      "Sandoy",
      "Streymoy",
      "Su uroy",
      "Sy ra Eysturoy",
      "Torshavn",
      "Vaga"
    ],
    "Fiji Islands": [
      "Central",
      "Eastern",
      "Northern",
      "South Pacific",
      "Western"
    ],
    "Finland": [
      "Ahvenanmaa",
      "Etela-Karjala",
      "Etela-Pohjanmaa",
      "Etela-Savo",
      "Etela-Suomen Laani",
      "Ita-Suomen Laani",
      "Ita-Uusimaa",
      "Kainuu",
      "Kanta-Hame",
      "Keski-Pohjanmaa",
      "Keski-Suomi",
      "Kymenlaakso",
      "Lansi-Suomen Laani",
      "Lappi",
      "Northern Savonia",
      "Ostrobothnia",
      "Oulun Laani",
      "Paijat-Hame",
      "Pirkanmaa",
      "Pohjanmaa",
      "Pohjois-Karjala",
      "Pohjois-Pohjanmaa",
      "Pohjois-Savo",
      "Saarijarvi",
      "Satakunta",
      "Southern Savonia",
      "Tavastia Proper",
      "Uleaborgs Lan",
      "Uusimaa",
      "Varsinais-Suomi"
    ],
    "France": [
      "Ain",
      "Aisne",
      "Albi Le Sequestre",
      "Allier",
      "Alpes-Cote dAzur",
      "Alpes-Maritimes",
      "Alpes-de-Haute-Provence",
      "Alsace",
      "Aquitaine",
      "Ardeche",
      "Ardennes",
      "Ariege",
      "Aube",
      "Aude",
      "Auvergne",
      "Aveyron",
      "Bas-Rhin",
      "Basse-Normandie",
      "Bouches-du-Rhone",
      "Bourgogne",
      "Bretagne",
      "Brittany",
      "Burgundy",
      "Calvados",
      "Cantal",
      "Cedex",
      "Centre",
      "Charente",
      "Charente-Maritime",
      "Cher",
      "Correze",
      "Corse-du-Sud",
      "Cote-d\\'Or",
      "Cotes-d\\'Armor",
      "Creuse",
      "Crolles",
      "Deux-Sevres",
      "Dordogne",
      "Doubs",
      "Drome",
      "Essonne",
      "Eure",
      "Eure-et-Loir",
      "Feucherolles",
      "Finistere",
      "Franche-Comte",
      "Gard",
      "Gers",
      "Gironde",
      "Haut-Rhin",
      "Haute-Corse",
      "Haute-Garonne",
      "Haute-Loire",
      "Haute-Marne",
      "Haute-Saone",
      "Haute-Savoie",
      "Haute-Vienne",
      "Hautes-Alpes",
      "Hautes-Pyrenees",
      "Hauts-de-Seine",
      "Herault",
      "Ile-de-France",
      "Ille-et-Vilaine",
      "Indre",
      "Indre-et-Loire",
      "Isere",
      "Jura",
      "Klagenfurt",
      "Landes",
      "Languedoc-Roussillon",
      "Larcay",
      "Le Castellet",
      "Le Creusot",
      "Limousin",
      "Loir-et-Cher",
      "Loire",
      "Loire-Atlantique",
      "Loiret",
      "Lorraine",
      "Lot",
      "Lot-et-Garonne",
      "Lower Normandy",
      "Lozere",
      "Maine-et-Loire",
      "Manche",
      "Marne",
      "Mayenne",
      "Meurthe-et-Moselle",
      "Meuse",
      "Midi-Pyrenees",
      "Morbihan",
      "Moselle",
      "Nievre",
      "Nord",
      "Nord-Pas-de-Calais",
      "Oise",
      "Orne",
      "Paris",
      "Pas-de-Calais",
      "Pays de la Loire",
      "Pays-de-la-Loire",
      "Picardy",
      "Puy-de-Dome",
      "Pyrenees-Atlantiques",
      "Pyrenees-Orientales",
      "Quelmes",
      "Rhone",
      "Rhone-Alpes",
      "Saint Ouen",
      "Saint Viatre",
      "Saone-et-Loire",
      "Sarthe",
      "Savoie",
      "Seine-Maritime",
      "Seine-Saint-Denis",
      "Seine-et-Marne",
      "Somme",
      "Sophia Antipolis",
      "Souvans",
      "Tarn",
      "Tarn-et-Garonne",
      "Territoire de Belfort",
      "Treignac",
      "Upper Normandy",
      "Val-d\\'Oise",
      "Val-de-Marne",
      "Var",
      "Vaucluse",
      "Vellise",
      "Vendee",
      "Vienne",
      "Vosges",
      "Yonne",
      "Yvelines"
    ],
    "French Guiana": [
      "Cayenne",
      "Saint-Laurent-du-Maroni"
    ],
    "French Polynesia": [
      "Iles du Vent",
      "Iles sous le Vent",
      "Marquesas",
      "Tuamotu",
      "Tubuai"
    ],
    "French Southern Territories": [
      "Amsterdam",
      "Crozet Islands",
      "Kerguelen"
    ],
    "Gabon": [
      "Estuaire",
      "Haut-Ogooue",
      "Moyen-Ogooue",
      "Ngounie",
      "Nyanga",
      "Ogooue-Ivindo",
      "Ogooue-Lolo",
      "Ogooue-Maritime",
      "Woleu-Ntem"
    ],
    "Gambia The": [
      "Banjul",
      "Basse",
      "Brikama",
      "Janjanbureh",
      "Kanifing",
      "Kerewan",
      "Kuntaur",
      "Mansakonko"
    ],
    "Georgia": [
      "Abhasia",
      "Ajaria",
      "Guria",
      "Imereti",
      "Kaheti",
      "Kvemo Kartli",
      "Mcheta-Mtianeti",
      "Racha",
      "Samagrelo-Zemo Svaneti",
      "Samche-Zhavaheti",
      "Shida Kartli",
      "Tbilisi"
    ],
    "Germany": [
      "Auvergne",
      "Baden-Wurttemberg",
      "Bavaria",
      "Bayern",
      "Beilstein Wurtt",
      "Berlin",
      "Brandenburg",
      "Bremen",
      "Dreisbach",
      "Freistaat Bayern",
      "Hamburg",
      "Hannover",
      "Heroldstatt",
      "Hessen",
      "Kortenberg",
      "Laasdorf",
      "Land Baden-Wurttemberg",
      "Land Bayern",
      "Land Brandenburg",
      "Land Hessen",
      "Land Mecklenburg-Vorpommern",
      "Land Nordrhein-Westfalen",
      "Land Rheinland-Pfalz",
      "Land Sachsen",
      "Land Sachsen-Anhalt",
      "Land Thuringen",
      "Lower Saxony",
      "Mecklenburg-Vorpommern",
      "Mulfingen",
      "Munich",
      "Neubeuern",
      "Niedersachsen",
      "Noord-Holland",
      "Nordrhein-Westfalen",
      "North Rhine-Westphalia",
      "Osterode",
      "Rheinland-Pfalz",
      "Rhineland-Palatinate",
      "Saarland",
      "Sachsen",
      "Sachsen-Anhalt",
      "Saxony",
      "Schleswig-Holstein",
      "Thuringia",
      "Webling",
      "Weinstrabe",
      "schlobborn"
    ],
    "Ghana": [
      "Ashanti",
      "Brong-Ahafo",
      "Central",
      "Eastern",
      "Greater Accra",
      "Northern",
      "Upper East",
      "Upper West",
      "Volta",
      "Western"
    ],
    "Gibraltar": [
      "Gibraltar"
    ],
    "Greece": [
      "Acharnes",
      "Ahaia",
      "Aitolia kai Akarnania",
      "Argolis",
      "Arkadia",
      "Arta",
      "Attica",
      "Attiki",
      "Ayion Oros",
      "Crete",
      "Dodekanisos",
      "Drama",
      "Evia",
      "Evritania",
      "Evros",
      "Evvoia",
      "Florina",
      "Fokis",
      "Fthiotis",
      "Grevena",
      "Halandri",
      "Halkidiki",
      "Hania",
      "Heraklion",
      "Hios",
      "Ilia",
      "Imathia",
      "Ioannina",
      "Iraklion",
      "Karditsa",
      "Kastoria",
      "Kavala",
      "Kefallinia",
      "Kerkira",
      "Kiklades",
      "Kilkis",
      "Korinthia",
      "Kozani",
      "Lakonia",
      "Larisa",
      "Lasithi",
      "Lesvos",
      "Levkas",
      "Magnisia",
      "Messinia",
      "Nomos Attikis",
      "Nomos Zakynthou",
      "Pella",
      "Pieria",
      "Piraios",
      "Preveza",
      "Rethimni",
      "Rodopi",
      "Samos",
      "Serrai",
      "Thesprotia",
      "Thessaloniki",
      "Trikala",
      "Voiotia",
      "West Greece",
      "Xanthi",
      "Zakinthos"
    ],
    "Greenland": [
      "Aasiaat",
      "Ammassalik",
      "Illoqqortoormiut",
      "Ilulissat",
      "Ivittuut",
      "Kangaatsiaq",
      "Maniitsoq",
      "Nanortalik",
      "Narsaq",
      "Nuuk",
      "Paamiut",
      "Qaanaaq",
      "Qaqortoq",
      "Qasigiannguit",
      "Qeqertarsuaq",
      "Sisimiut",
      "Udenfor kommunal inddeling",
      "Upernavik",
      "Uummannaq"
    ],
    "Grenada": [
      "Carriacou-Petite Martinique",
      "Saint Andrew",
      "Saint Davids",
      "Saint George\\'s",
      "Saint John",
      "Saint Mark",
      "Saint Patrick"
    ],
    "Guadeloupe": [
      "Basse-Terre",
      "Grande-Terre",
      "Iles des Saintes",
      "La Desirade",
      "Marie-Galante",
      "Saint Barthelemy",
      "Saint Martin"
    ],
    "Guam": [
      "Agana Heights",
      "Agat",
      "Barrigada",
      "Chalan-Pago-Ordot",
      "Dededo",
      "Hagatna",
      "Inarajan",
      "Mangilao",
      "Merizo",
      "Mongmong-Toto-Maite",
      "Santa Rita",
      "Sinajana",
      "Talofofo",
      "Tamuning",
      "Yigo",
      "Yona"
    ],
    "Guatemala": [
      "Alta Verapaz",
      "Baja Verapaz",
      "Chimaltenango",
      "Chiquimula",
      "El Progreso",
      "Escuintla",
      "Guatemala",
      "Huehuetenango",
      "Izabal",
      "Jalapa",
      "Jutiapa",
      "Peten",
      "Quezaltenango",
      "Quiche",
      "Retalhuleu",
      "Sacatepequez",
      "San Marcos",
      "Santa Rosa",
      "Solola",
      "Suchitepequez",
      "Totonicapan",
      "Zacapa"
    ],
    "Guernsey and Alderney": [
      "Alderney",
      "Castel",
      "Forest",
      "Saint Andrew",
      "Saint Martin",
      "Saint Peter Port",
      "Saint Pierre du Bois",
      "Saint Sampson",
      "Saint Saviour",
      "Sark",
      "Torteval",
      "Vale"
    ],
    "Guinea": [
      "Beyla",
      "Boffa",
      "Boke",
      "Conakry",
      "Coyah",
      "Dabola",
      "Dalaba",
      "Dinguiraye",
      "Faranah",
      "Forecariah",
      "Fria",
      "Gaoual",
      "Gueckedou",
      "Kankan",
      "Kerouane",
      "Kindia",
      "Kissidougou",
      "Koubia",
      "Koundara",
      "Kouroussa",
      "Labe",
      "Lola",
      "Macenta",
      "Mali",
      "Mamou",
      "Mandiana",
      "Nzerekore",
      "Pita",
      "Siguiri",
      "Telimele",
      "Tougue",
      "Yomou"
    ],
    "Guinea-Bissau": [
      "Bafata",
      "Bissau",
      "Bolama",
      "Cacheu",
      "Gabu",
      "Oio",
      "Quinara",
      "Tombali"
    ],
    "Guyana": [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerar",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo"
    ],
    "Haiti": [
      "Artibonite",
      "Centre",
      "Grand\\'Anse",
      "Nord",
      "Nord-Est",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Est"
    ],
    "Heard and McDonald Islands": [
      "Heard and McDonald Islands"
    ],
    "Honduras": [
      "Atlantida",
      "Choluteca",
      "Colon",
      "Comayagua",
      "Copan",
      "Cortes",
      "Distrito Central",
      "El Paraiso",
      "Francisco Morazan",
      "Gracias a Dios",
      "Intibuca",
      "Islas de la Bahia",
      "La Paz",
      "Lempira",
      "Ocotepeque",
      "Olancho",
      "Santa Barbara",
      "Valle",
      "Yoro"
    ],
    "Hong Kong S.A.R.": [
      "Hong Kong"
    ],
    "Hungary": [
      "Bacs-Kiskun",
      "Baranya",
      "Bekes",
      "Borsod-Abauj-Zemplen",
      "Budapest",
      "Csongrad",
      "Fejer",
      "Gyor-Moson-Sopron",
      "Hajdu-Bihar",
      "Heves",
      "Jasz-Nagykun-Szolnok",
      "Komarom-Esztergom",
      "Nograd",
      "Pest",
      "Somogy",
      "Szabolcs-Szatmar-Bereg",
      "Tolna",
      "Vas",
      "Veszprem",
      "Zala"
    ],
    "Iceland": [
      "Austurland",
      "Gullbringusysla",
      "Hofu borgarsva i",
      "Nor urland eystra",
      "Nor urland vestra",
      "Su urland",
      "Su urnes",
      "Vestfir ir",
      "Vesturland"
    ],
    "India": [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kenmore",
      "Kerala",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Narora",
      "Natwar",
      "Odisha",
      "Paschim Medinipur",
      "Pondicherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "Vaishali",
      "West Bengal"
    ],
    "Indonesia": [
      "Aceh",
      "Bali",
      "Bangka-Belitung",
      "Banten",
      "Bengkulu",
      "Gandaria",
      "Gorontalo",
      "Jakarta",
      "Jambi",
      "Jawa Barat",
      "Jawa Tengah",
      "Jawa Timur",
      "Kalimantan Barat",
      "Kalimantan Selatan",
      "Kalimantan Tengah",
      "Kalimantan Timur",
      "Kendal",
      "Lampung",
      "Maluku",
      "Maluku Utara",
      "Nusa Tenggara Barat",
      "Nusa Tenggara Timur",
      "Papua",
      "Riau",
      "Riau Kepulauan",
      "Solo",
      "Sulawesi Selatan",
      "Sulawesi Tengah",
      "Sulawesi Tenggara",
      "Sulawesi Utara",
      "Sumatera Barat",
      "Sumatera Selatan",
      "Sumatera Utara",
      "Yogyakarta"
    ],
    "Iran": [
      "Ardabil",
      "Azarbayjan-e Bakhtari",
      "Azarbayjan-e Khavari",
      "Bushehr",
      "Chahar Mahal-e Bakhtiari",
      "Esfahan",
      "Fars",
      "Gilan",
      "Golestan",
      "Hamadan",
      "Hormozgan",
      "Ilam",
      "Kerman",
      "Kermanshah",
      "Khorasan",
      "Khuzestan",
      "Kohgiluyeh-e Boyerahmad",
      "Kordestan",
      "Lorestan",
      "Markazi",
      "Mazandaran",
      "Ostan-e Esfahan",
      "Qazvin",
      "Qom",
      "Semnan",
      "Sistan-e Baluchestan",
      "Tehran",
      "Yazd",
      "Zanjan"
    ],
    "Iraq": [
      "Babil",
      "Baghdad",
      "Dahuk",
      "Dhi Qar",
      "Diyala",
      "Erbil",
      "Irbil",
      "Karbala",
      "Kurdistan",
      "Maysan",
      "Ninawa",
      "Salah-ad-Din",
      "Wasit",
      "al-Anbar",
      "al-Basrah",
      "al-Muthanna",
      "al-Qadisiyah",
      "an-Najaf",
      "as-Sulaymaniyah",
      "at-Ta\\'mim"
    ],
    "Ireland": [
      "Armagh",
      "Carlow",
      "Cavan",
      "Clare",
      "Cork",
      "Donegal",
      "Dublin",
      "Galway",
      "Kerry",
      "Kildare",
      "Kilkenny",
      "Laois",
      "Leinster",
      "Leitrim",
      "Limerick",
      "Loch Garman",
      "Longford",
      "Louth",
      "Mayo",
      "Meath",
      "Monaghan",
      "Offaly",
      "Roscommon",
      "Sligo",
      "Tipperary North Riding",
      "Tipperary South Riding",
      "Ulster",
      "Waterford",
      "Westmeath",
      "Wexford",
      "Wicklow"
    ],
    "Israel": [
      "Beit Hanania",
      "Ben Gurion Airport",
      "Bethlehem",
      "Caesarea",
      "Centre",
      "Gaza",
      "Hadaron",
      "Haifa District",
      "Hamerkaz",
      "Hazafon",
      "Hebron",
      "Jaffa",
      "Jerusalem",
      "Khefa",
      "Kiryat Yam",
      "Lower Galilee",
      "Qalqilya",
      "Talme Elazar",
      "Tel Aviv",
      "Tsafon",
      "Umm El Fahem",
      "Yerushalayim"
    ],
    "Italy": [
      "Abruzzi",
      "Abruzzo",
      "Agrigento",
      "Alessandria",
      "Ancona",
      "Arezzo",
      "Ascoli Piceno",
      "Asti",
      "Avellino",
      "Bari",
      "Basilicata",
      "Belluno",
      "Benevento",
      "Bergamo",
      "Biella",
      "Bologna",
      "Bolzano",
      "Brescia",
      "Brindisi",
      "Calabria",
      "Campania",
      "Cartoceto",
      "Caserta",
      "Catania",
      "Chieti",
      "Como",
      "Cosenza",
      "Cremona",
      "Cuneo",
      "Emilia-Romagna",
      "Ferrara",
      "Firenze",
      "Florence",
      "Forli-Cesena ",
      "Friuli-Venezia Giulia",
      "Frosinone",
      "Genoa",
      "Gorizia",
      "L\\'Aquila",
      "Lazio",
      "Lecce",
      "Lecco",
      "Lecco Province",
      "Liguria",
      "Lodi",
      "Lombardia",
      "Lombardy",
      "Macerata",
      "Mantova",
      "Marche",
      "Messina",
      "Milan",
      "Modena",
      "Molise",
      "Molteno",
      "Montenegro",
      "Monza and Brianza",
      "Naples",
      "Novara",
      "Padova",
      "Parma",
      "Pavia",
      "Perugia",
      "Pesaro-Urbino",
      "Piacenza",
      "Piedmont",
      "Piemonte",
      "Pisa",
      "Pordenone",
      "Potenza",
      "Puglia",
      "Reggio Emilia",
      "Rimini",
      "Roma",
      "Salerno",
      "Sardegna",
      "Sassari",
      "Savona",
      "Sicilia",
      "Siena",
      "Sondrio",
      "South Tyrol",
      "Taranto",
      "Teramo",
      "Torino",
      "Toscana",
      "Trapani",
      "Trentino-Alto Adige",
      "Trento",
      "Treviso",
      "Udine",
      "Umbria",
      "Valle d\\'Aosta",
      "Varese",
      "Veneto",
      "Venezia",
      "Verbano-Cusio-Ossola",
      "Vercelli",
      "Verona",
      "Vicenza",
      "Viterbo"
    ],
    "Jamaica": [
      "Buxoro Viloyati",
      "Clarendon",
      "Hanover",
      "Kingston",
      "Manchester",
      "Portland",
      "Saint Andrews",
      "Saint Ann",
      "Saint Catherine",
      "Saint Elizabeth",
      "Saint James",
      "Saint Mary",
      "Saint Thomas",
      "Trelawney",
      "Westmoreland"
    ],
    "Japan": [
      "Aichi",
      "Akita",
      "Aomori",
      "Chiba",
      "Ehime",
      "Fukui",
      "Fukuoka",
      "Fukushima",
      "Gifu",
      "Gumma",
      "Hiroshima",
      "Hokkaido",
      "Hyogo",
      "Ibaraki",
      "Ishikawa",
      "Iwate",
      "Kagawa",
      "Kagoshima",
      "Kanagawa",
      "Kanto",
      "Kochi",
      "Kumamoto",
      "Kyoto",
      "Mie",
      "Miyagi",
      "Miyazaki",
      "Nagano",
      "Nagasaki",
      "Nara",
      "Niigata",
      "Oita",
      "Okayama",
      "Okinawa",
      "Osaka",
      "Saga",
      "Saitama",
      "Shiga",
      "Shimane",
      "Shizuoka",
      "Tochigi",
      "Tokushima",
      "Tokyo",
      "Tottori",
      "Toyama",
      "Wakayama",
      "Yamagata",
      "Yamaguchi",
      "Yamanashi"
    ],
    "Jersey": [
      "Grouville",
      "Saint Brelade",
      "Saint Clement",
      "Saint Helier",
      "Saint John",
      "Saint Lawrence",
      "Saint Martin",
      "Saint Mary",
      "Saint Peter",
      "Saint Saviour",
      "Trinity"
    ],
    "Jordan": [
      "\\'Ajlun",
      "Amman",
      "Irbid",
      "Jarash",
      "Ma\\'an",
      "Madaba",
      "al-\\'Aqabah",
      "al-Balqa\\'",
      "al-Karak",
      "al-Mafraq",
      "at-Tafilah",
      "az-Zarqa\\'"
    ],
    "Kazakhstan": [
      "Akmecet",
      "Akmola",
      "Aktobe",
      "Almati",
      "Atirau",
      "Batis Kazakstan",
      "Burlinsky Region",
      "Karagandi",
      "Kostanay",
      "Mankistau",
      "Ontustik Kazakstan",
      "Pavlodar",
      "Sigis Kazakstan",
      "Soltustik Kazakstan",
      "Taraz"
    ],
    "Kenya": [
      "Central",
      "Coast",
      "Eastern",
      "Nairobi",
      "North Eastern",
      "Nyanza",
      "Rift Valley",
      "Western"
    ],
    "Kiribati": [
      "Abaiang",
      "Abemana",
      "Aranuka",
      "Arorae",
      "Banaba",
      "Beru",
      "Butaritari",
      "Kiritimati",
      "Kuria",
      "Maiana",
      "Makin",
      "Marakei",
      "Nikunau",
      "Nonouti",
      "Onotoa",
      "Phoenix Islands",
      "Tabiteuea North",
      "Tabiteuea South",
      "Tabuaeran",
      "Tamana",
      "Tarawa North",
      "Tarawa South",
      "Teraina"
    ],
    "Korea South": [
      "Busan",
      "Cheju",
      "Chollabuk",
      "Chollanam",
      "Chungbuk",
      "Chungcheongbuk",
      "Chungcheongnam",
      "Chungnam",
      "Daegu",
      "Gangwon-do",
      "Goyang-si",
      "Gyeonggi-do",
      "Gyeongsang ",
      "Gyeongsangnam-do",
      "Incheon",
      "Jeju-Si",
      "Jeonbuk",
      "Kangweon",
      "Kwangju",
      "Kyeonggi",
      "Kyeongsangbuk",
      "Kyeongsangnam",
      "Kyonggi-do",
      "Kyungbuk-Do",
      "Kyunggi-Do",
      "Kyunggi-do",
      "Pusan",
      "Seoul",
      "Sudogwon",
      "Taegu",
      "Taejeon",
      "Taejon-gwangyoksi",
      "Ulsan",
      "Wonju",
      "gwangyoksi"
    ],
    "Kuwait": [
      "Al Asimah",
      "Hawalli",
      "Mishref",
      "Qadesiya",
      "Safat",
      "Salmiya",
      "al-Ahmadi",
      "al-Farwaniyah",
      "al-Jahra",
      "al-Kuwayt"
    ],
    "Kyrgyzstan": [
      "Batken",
      "Bishkek",
      "Chui",
      "Issyk-Kul",
      "Jalal-Abad",
      "Naryn",
      "Osh",
      "Talas"
    ],
    "Laos": [
      "Attopu",
      "Bokeo",
      "Bolikhamsay",
      "Champasak",
      "Houaphanh",
      "Khammouane",
      "Luang Nam Tha",
      "Luang Prabang",
      "Oudomxay",
      "Phongsaly",
      "Saravan",
      "Savannakhet",
      "Sekong",
      "Viangchan Prefecture",
      "Viangchan Province",
      "Xaignabury",
      "Xiang Khuang"
    ],
    "Latvia": [
      "Aizkraukles",
      "Aluksnes",
      "Balvu",
      "Bauskas",
      "Cesu",
      "Daugavpils",
      "Daugavpils City",
      "Dobeles",
      "Gulbenes",
      "Jekabspils",
      "Jelgava",
      "Jelgavas",
      "Jurmala City",
      "Kraslavas",
      "Kuldigas",
      "Liepaja",
      "Liepajas",
      "Limbazhu",
      "Ludzas",
      "Madonas",
      "Ogres",
      "Preilu",
      "Rezekne",
      "Rezeknes",
      "Riga",
      "Rigas",
      "Saldus",
      "Talsu",
      "Tukuma",
      "Valkas",
      "Valmieras",
      "Ventspils",
      "Ventspils City"
    ],
    "Lebanon": [
      "Beirut",
      "Jabal Lubnan",
      "Mohafazat Liban-Nord",
      "Mohafazat Mont-Liban",
      "Sidon",
      "al-Biqa",
      "al-Janub",
      "an-Nabatiyah",
      "ash-Shamal"
    ],
    "Lesotho": [
      "Berea",
      "Butha-Buthe",
      "Leribe",
      "Mafeteng",
      "Maseru",
      "Mohale\\'s Hoek",
      "Mokhotlong",
      "Qacha\\'s Nek",
      "Quthing",
      "Thaba-Tseka"
    ],
    "Liberia": [
      "Bomi",
      "Bong",
      "Grand Bassa",
      "Grand Cape Mount",
      "Grand Gedeh",
      "Loffa",
      "Margibi",
      "Maryland and Grand Kru",
      "Montserrado",
      "Nimba",
      "Rivercess",
      "Sinoe"
    ],
    "Libya": [
      "Ajdabiya",
      "Banghazi",
      "Darnah",
      "Ghadamis",
      "Gharyan",
      "Misratah",
      "Murzuq",
      "Sabha",
      "Sawfajjin",
      "Surt",
      "Tarabulus",
      "Tarhunah",
      "Tripolitania",
      "Tubruq",
      "Yafran",
      "Zlitan",
      "al-\\'Aziziyah",
      "al-Fatih",
      "al-Jabal al Akhdar",
      "al-Jufrah",
      "al-Khums",
      "al-Kufrah",
      "an-Nuqat al-Khams",
      "ash-Shati\\'",
      "az-Zawiyah"
    ],
    "Liechtenstein": [
      "Balzers",
      "Eschen",
      "Gamprin",
      "Mauren",
      "Planken",
      "Ruggell",
      "Schaan",
      "Schellenberg",
      "Triesen",
      "Triesenberg",
      "Vaduz"
    ],
    "Lithuania": [
      "Alytaus",
      "Anyksciai",
      "Kauno",
      "Klaipedos",
      "Marijampoles",
      "Panevezhio",
      "Panevezys",
      "Shiauliu",
      "Taurages",
      "Telshiu",
      "Telsiai",
      "Utenos",
      "Vilniaus"
    ],
    "Luxembourg": [
      "Capellen",
      "Clervaux",
      "Diekirch",
      "Echternach",
      "Esch-sur-Alzette",
      "Grevenmacher",
      "Luxembourg",
      "Mersch",
      "Redange",
      "Remich",
      "Vianden",
      "Wiltz"
    ],
    "Macau S.A.R.": [
      "Macau"
    ],
    "Macedonia": [
      "Berovo",
      "Bitola",
      "Brod",
      "Debar",
      "Delchevo",
      "Demir Hisar",
      "Gevgelija",
      "Gostivar",
      "Kavadarci",
      "Kichevo",
      "Kochani",
      "Kratovo",
      "Kriva Palanka",
      "Krushevo",
      "Kumanovo",
      "Negotino",
      "Ohrid",
      "Prilep",
      "Probishtip",
      "Radovish",
      "Resen",
      "Shtip",
      "Skopje",
      "Struga",
      "Strumica",
      "Sveti Nikole",
      "Tetovo",
      "Valandovo",
      "Veles",
      "Vinica"
    ],
    "Madagascar": [
      "Antananarivo",
      "Antsiranana",
      "Fianarantsoa",
      "Mahajanga",
      "Toamasina",
      "Toliary"
    ],
    "Malawi": [
      "Balaka",
      "Blantyre City",
      "Chikwawa",
      "Chiradzulu",
      "Chitipa",
      "Dedza",
      "Dowa",
      "Karonga",
      "Kasungu",
      "Lilongwe City",
      "Machinga",
      "Mangochi",
      "Mchinji",
      "Mulanje",
      "Mwanza",
      "Mzimba",
      "Mzuzu City",
      "Nkhata Bay",
      "Nkhotakota",
      "Nsanje",
      "Ntcheu",
      "Ntchisi",
      "Phalombe",
      "Rumphi",
      "Salima",
      "Thyolo",
      "Zomba Municipality"
    ],
    "Malaysia": [
      "Johor",
      "Kedah",
      "Kelantan",
      "Kuala Lumpur",
      "Labuan",
      "Melaka",
      "Negeri Johor",
      "Negeri Sembilan",
      "Pahang",
      "Penang",
      "Perak",
      "Perlis",
      "Pulau Pinang",
      "Sabah",
      "Sarawak",
      "Selangor",
      "Sembilan",
      "Terengganu"
    ],
    "Maldives": [
      "Alif Alif",
      "Alif Dhaal",
      "Baa",
      "Dhaal",
      "Faaf",
      "Gaaf Alif",
      "Gaaf Dhaal",
      "Ghaviyani",
      "Haa Alif",
      "Haa Dhaal",
      "Kaaf",
      "Laam",
      "Lhaviyani",
      "Male",
      "Miim",
      "Nuun",
      "Raa",
      "Shaviyani",
      "Siin",
      "Thaa",
      "Vaav"
    ],
    "Mali": [
      "Bamako",
      "Gao",
      "Kayes",
      "Kidal",
      "Koulikoro",
      "Mopti",
      "Segou",
      "Sikasso",
      "Tombouctou"
    ],
    "Malta": [
      "Gozo and Comino",
      "Inner Harbour",
      "Northern",
      "Outer Harbour",
      "South Eastern",
      "Valletta",
      "Western"
    ],
    "Man (Isle of)": [
      "Castletown",
      "Douglas",
      "Laxey",
      "Onchan",
      "Peel",
      "Port Erin",
      "Port Saint Mary",
      "Ramsey"
    ],
    "Marshall Islands": [
      "Ailinlaplap",
      "Ailuk",
      "Arno",
      "Aur",
      "Bikini",
      "Ebon",
      "Enewetak",
      "Jabat",
      "Jaluit",
      "Kili",
      "Kwajalein",
      "Lae",
      "Lib",
      "Likiep",
      "Majuro",
      "Maloelap",
      "Mejit",
      "Mili",
      "Namorik",
      "Namu",
      "Rongelap",
      "Ujae",
      "Utrik",
      "Wotho",
      "Wotje"
    ],
    "Martinique": [
      "Fort-de-France",
      "La Trinite",
      "Le Marin",
      "Saint-Pierre"
    ],
    "Mauritania": [
      "Adrar",
      "Assaba",
      "Brakna",
      "Dhakhlat Nawadibu",
      "Hudh-al-Gharbi",
      "Hudh-ash-Sharqi",
      "Inshiri",
      "Nawakshut",
      "Qidimagha",
      "Qurqul",
      "Taqant",
      "Tiris Zammur",
      "Trarza"
    ],
    "Mauritius": [
      "Black River",
      "Eau Coulee",
      "Flacq",
      "Floreal",
      "Grand Port",
      "Moka",
      "Pamplempousses",
      "Plaines Wilhelm",
      "Port Louis",
      "Riviere du Rempart",
      "Rodrigues",
      "Rose Hill",
      "Savanne"
    ],
    "Mayotte": [
      "Mayotte",
      "Pamanzi"
    ],
    "Mexico": [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Coahuila",
      "Colima",
      "Distrito Federal",
      "Durango",
      "Estado de Mexico",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Mexico",
      "Michoacan",
      "Morelos",
      "Nayarit",
      "Nuevo Leon",
      "Oaxaca",
      "Puebla",
      "Queretaro",
      "Quintana Roo",
      "San Luis Potosi",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz",
      "Yucatan",
      "Zacatecas"
    ],
    "Micronesia": [
      "Chuuk",
      "Kusaie",
      "Pohnpei",
      "Yap"
    ],
    "Moldova": [
      "Balti",
      "Cahul",
      "Chisinau",
      "Chisinau Oras",
      "Edinet",
      "Gagauzia",
      "Lapusna",
      "Orhei",
      "Soroca",
      "Taraclia",
      "Tighina",
      "Transnistria",
      "Ungheni"
    ],
    "Monaco": [
      "Fontvieille",
      "La Condamine",
      "Monaco-Ville",
      "Monte Carlo"
    ],
    "Mongolia": [
      "Arhangaj",
      "Bajan-Olgij",
      "Bajanhongor",
      "Bulgan",
      "Darhan-Uul",
      "Dornod",
      "Dornogovi",
      "Dundgovi",
      "Govi-Altaj",
      "Govisumber",
      "Hentij",
      "Hovd",
      "Hovsgol",
      "Omnogovi",
      "Orhon",
      "Ovorhangaj",
      "Selenge",
      "Suhbaatar",
      "Tov",
      "Ulaanbaatar",
      "Uvs",
      "Zavhan"
    ],
    "Montserrat": [
      "Montserrat"
    ],
    "Morocco": [
      "Agadir",
      "Casablanca",
      "Chaouia-Ouardigha",
      "Doukkala-Abda",
      "Fes-Boulemane",
      "Gharb-Chrarda-Beni Hssen",
      "Guelmim",
      "Kenitra",
      "Marrakech-Tensift-Al Haouz",
      "Meknes-Tafilalet",
      "Oriental",
      "Oujda",
      "Province de Tanger",
      "Rabat-Sale-Zammour-Zaer",
      "Sala Al Jadida",
      "Settat",
      "Souss Massa-Draa",
      "Tadla-Azilal",
      "Tangier-Tetouan",
      "Taza-Al Hoceima-Taounate",
      "Wilaya de Casablanca",
      "Wilaya de Rabat-Sale"
    ],
    "Mozambique": [
      "Cabo Delgado",
      "Gaza",
      "Inhambane",
      "Manica",
      "Maputo",
      "Maputo Provincia",
      "Nampula",
      "Niassa",
      "Sofala",
      "Tete",
      "Zambezia"
    ],
    "Myanmar": [
      "Ayeyarwady",
      "Bago",
      "Chin",
      "Kachin",
      "Kayah",
      "Kayin",
      "Magway",
      "Mandalay",
      "Mon",
      "Nay Pyi Taw",
      "Rakhine",
      "Sagaing",
      "Shan",
      "Tanintharyi",
      "Yangon"
    ],
    "Namibia": [
      "Caprivi",
      "Erongo",
      "Hardap",
      "Karas",
      "Kavango",
      "Khomas",
      "Kunene",
      "Ohangwena",
      "Omaheke",
      "Omusati",
      "Oshana",
      "Oshikoto",
      "Otjozondjupa"
    ],
    "Nauru": [
      "Yaren"
    ],
    "Nepal": [
      "Bagmati",
      "Bheri",
      "Dhawalagiri",
      "Gandaki",
      "Janakpur",
      "Karnali",
      "Koshi",
      "Lumbini",
      "Mahakali",
      "Mechi",
      "Narayani",
      "Rapti",
      "Sagarmatha",
      "Seti"
    ],
    "Netherlands Antilles": [
      "Bonaire",
      "Curacao",
      "Saba",
      "Sint Eustatius",
      "Sint Maarten"
    ],
    "Netherlands The": [
      "Amsterdam",
      "Benelux",
      "Drenthe",
      "Flevoland",
      "Friesland",
      "Gelderland",
      "Groningen",
      "Limburg",
      "Noord-Brabant",
      "Noord-Holland",
      "Overijssel",
      "South Holland",
      "Utrecht",
      "Zeeland",
      "Zuid-Holland"
    ],
    "New Caledonia": [
      "Iles",
      "Nord",
      "Sud"
    ],
    "New Zealand": [
      "Area Outside Region",
      "Auckland",
      "Bay of Plenty",
      "Canterbury",
      "Christchurch",
      "Gisborne",
      "Hawke\\'s Bay",
      "Manawatu-Wanganui",
      "Marlborough",
      "Nelson",
      "Northland",
      "Otago",
      "Rodney",
      "Southland",
      "Taranaki",
      "Tasman",
      "Waikato",
      "Wellington",
      "West Coast"
    ],
    "Nicaragua": [
      "Atlantico Norte",
      "Atlantico Sur",
      "Boaco",
      "Carazo",
      "Chinandega",
      "Chontales",
      "Esteli",
      "Granada",
      "Jinotega",
      "Leon",
      "Madriz",
      "Managua",
      "Masaya",
      "Matagalpa",
      "Nueva Segovia",
      "Rio San Juan",
      "Rivas"
    ],
    "Niger": [
      "Agadez",
      "Diffa",
      "Dosso",
      "Maradi",
      "Niamey",
      "Tahoua",
      "Tillabery",
      "Zinder"
    ],
    "Nigeria": [
      "Abia",
      "Abuja Federal Capital Territor",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nassarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara"
    ],
    "Niue": [
      "Niue"
    ],
    "Norfolk Island": [
      "Norfolk Island"
    ],
    "Northern Mariana Islands": [
      "Northern Islands",
      "Rota",
      "Saipan",
      "Tinian"
    ],
    "Norway": [
      "Akershus",
      "Aust Agder",
      "Bergen",
      "Buskerud",
      "Finnmark",
      "Hedmark",
      "Hordaland",
      "Moere og Romsdal",
      "Nord Trondelag",
      "Nordland",
      "Oestfold",
      "Oppland",
      "Oslo",
      "Rogaland",
      "Soer Troendelag",
      "Sogn og Fjordane",
      "Stavern",
      "Sykkylven",
      "Telemark",
      "Troms",
      "Vest Agder",
      "Vestfold",
      "ÃƒÂƒÃ†Â’ÃƒÂ‚Ã‹Âœstfold"
    ],
    "Oman": [
      "Al Buraimi",
      "Dhufar",
      "Masqat",
      "Musandam",
      "Rusayl",
      "Wadi Kabir",
      "ad-Dakhiliyah",
      "adh-Dhahirah",
      "al-Batinah",
      "ash-Sharqiyah"
    ],
    "Pakistan": [
      "Baluchistan",
      "Federal Capital Area",
      "Federally administered Tribal ",
      "North-West Frontier",
      "Northern Areas",
      "Punjab",
      "Sind"
    ],
    "Palau": [
      "Aimeliik",
      "Airai",
      "Angaur",
      "Hatobohei",
      "Kayangel",
      "Koror",
      "Melekeok",
      "Ngaraard",
      "Ngardmau",
      "Ngaremlengui",
      "Ngatpang",
      "Ngchesar",
      "Ngerchelong",
      "Ngiwal",
      "Peleliu",
      "Sonsorol"
    ],
    "Palestinian Territory Occupied": [
      "Ariha",
      "Bayt Lahm",
      "Bethlehem",
      "Dayr-al-Balah",
      "Ghazzah",
      "Ghazzah ash-Shamaliyah",
      "Janin",
      "Khan Yunis",
      "Nabulus",
      "Qalqilyah",
      "Rafah",
      "Ram Allah wal-Birah",
      "Salfit",
      "Tubas",
      "Tulkarm",
      "al-Khalil",
      "al-Quds"
    ],
    "Panama": [
      "Bocas del Toro",
      "Chiriqui",
      "Cocle",
      "Colon",
      "Darien",
      "Embera",
      "Herrera",
      "Kuna Yala",
      "Los Santos",
      "Ngobe Bugle",
      "Panama",
      "Veraguas"
    ],
    "Papua new Guinea": [
      "East New Britain",
      "East Sepik",
      "Eastern Highlands",
      "Enga",
      "Fly River",
      "Gulf",
      "Madang",
      "Manus",
      "Milne Bay",
      "Morobe",
      "National Capital District",
      "New Ireland",
      "North Solomons",
      "Oro",
      "Sandaun",
      "Simbu",
      "Southern Highlands",
      "West New Britain",
      "Western Highlands"
    ],
    "Paraguay": [
      "Alto Paraguay",
      "Alto Parana",
      "Amambay",
      "Asuncion",
      "Boqueron",
      "Caaguazu",
      "Caazapa",
      "Canendiyu",
      "Central",
      "Concepcion",
      "Cordillera",
      "Guaira",
      "Itapua",
      "Misiones",
      "Neembucu",
      "Paraguari",
      "Presidente Hayes",
      "San Pedro"
    ],
    "Peru": [
      "Amazonas",
      "Ancash",
      "Apurimac",
      "Arequipa",
      "Ayacucho",
      "Cajamarca",
      "Cusco",
      "Huancavelica",
      "Huanuco",
      "Ica",
      "Junin",
      "La Libertad",
      "Lambayeque",
      "Lima y Callao",
      "Loreto",
      "Madre de Dios",
      "Moquegua",
      "Pasco",
      "Piura",
      "Puno",
      "San Martin",
      "Tacna",
      "Tumbes",
      "Ucayali"
    ],
    "Philippines": [
      "Batangas",
      "Bicol",
      "Bulacan",
      "Cagayan",
      "Caraga",
      "Central Luzon",
      "Central Mindanao",
      "Central Visayas",
      "Cordillera",
      "Davao",
      "Eastern Visayas",
      "Greater Metropolitan Area",
      "Ilocos",
      "Laguna",
      "Luzon",
      "Mactan",
      "Metropolitan Manila Area",
      "Muslim Mindanao",
      "Northern Mindanao",
      "Southern Mindanao",
      "Southern Tagalog",
      "Western Mindanao",
      "Western Visayas"
    ],
    "Pitcairn Island": [
      "Pitcairn Island"
    ],
    "Poland": [
      "Biale Blota",
      "Dobroszyce",
      "Dolnoslaskie",
      "Dziekanow Lesny",
      "Hopowo",
      "Kartuzy",
      "Koscian",
      "Krakow",
      "Kujawsko-Pomorskie",
      "Lodzkie",
      "Lubelskie",
      "Lubuskie",
      "Malomice",
      "Malopolskie",
      "Mazowieckie",
      "Mirkow",
      "Opolskie",
      "Ostrowiec",
      "Podkarpackie",
      "Podlaskie",
      "Polska",
      "Pomorskie",
      "Poznan",
      "Pruszkow",
      "Rymanowska",
      "Rzeszow",
      "Slaskie",
      "Stare Pole",
      "Swietokrzyskie",
      "Warminsko-Mazurskie",
      "Warsaw",
      "Wejherowo",
      "Wielkopolskie",
      "Wroclaw",
      "Zachodnio-Pomorskie",
      "Zukowo"
    ],
    "Portugal": [
      "Abrantes",
      "Acores",
      "Alentejo",
      "Algarve",
      "Braga",
      "Centro",
      "Distrito de Leiria",
      "Distrito de Viana do Castelo",
      "Distrito de Vila Real",
      "Distrito do Porto",
      "Lisboa e Vale do Tejo",
      "Madeira",
      "Norte",
      "Paivas"
    ],
    "Puerto Rico": [
      "Arecibo",
      "Bayamon",
      "Carolina",
      "Florida",
      "Guayama",
      "Humacao",
      "Mayaguez-Aguadilla",
      "Ponce",
      "Salinas",
      "San Juan"
    ],
    "Qatar": [
      "Doha",
      "Jarian-al-Batnah",
      "Umm Salal",
      "ad-Dawhah",
      "al-Ghuwayriyah",
      "al-Jumayliyah",
      "al-Khawr",
      "al-Wakrah",
      "ar-Rayyan",
      "ash-Shamal"
    ],
    "Reunion": [
      "Saint-Benoit",
      "Saint-Denis",
      "Saint-Paul",
      "Saint-Pierre"
    ],
    "Romania": [
      "Alba",
      "Arad",
      "Arges",
      "Bacau",
      "Bihor",
      "Bistrita-Nasaud",
      "Botosani",
      "Braila",
      "Brasov",
      "Bucuresti",
      "Buzau",
      "Calarasi",
      "Caras-Severin",
      "Cluj",
      "Constanta",
      "Covasna",
      "Dambovita",
      "Dolj",
      "Galati",
      "Giurgiu",
      "Gorj",
      "Harghita",
      "Hunedoara",
      "Ialomita",
      "Iasi",
      "Ilfov",
      "Maramures",
      "Mehedinti",
      "Mures",
      "Neamt",
      "Olt",
      "Prahova",
      "Salaj",
      "Satu Mare",
      "Sibiu",
      "Sondelor",
      "Suceava",
      "Teleorman",
      "Timis",
      "Tulcea",
      "Valcea",
      "Vaslui",
      "Vrancea"
    ],
    "Russia": [
      "Adygeja",
      "Aga",
      "Alanija",
      "Altaj",
      "Amur",
      "Arhangelsk",
      "Astrahan",
      "Bashkortostan",
      "Belgorod",
      "Brjansk",
      "Burjatija",
      "Chechenija",
      "Cheljabinsk",
      "Chita",
      "Chukotka",
      "Chuvashija",
      "Dagestan",
      "Evenkija",
      "Gorno-Altaj",
      "Habarovsk",
      "Hakasija",
      "Hanty-Mansija",
      "Ingusetija",
      "Irkutsk",
      "Ivanovo",
      "Jamalo-Nenets",
      "Jaroslavl",
      "Jevrej",
      "Kabardino-Balkarija",
      "Kaliningrad",
      "Kalmykija",
      "Kaluga",
      "Kamchatka",
      "Karachaj-Cherkessija",
      "Karelija",
      "Kemerovo",
      "Khabarovskiy Kray",
      "Kirov",
      "Komi",
      "Komi-Permjakija",
      "Korjakija",
      "Kostroma",
      "Krasnodar",
      "Krasnojarsk",
      "Krasnoyarskiy Kray",
      "Kurgan",
      "Kursk",
      "Leningrad",
      "Lipeck",
      "Magadan",
      "Marij El",
      "Mordovija",
      "Moscow",
      "Moskovskaja Oblast",
      "Moskovskaya Oblast",
      "Moskva",
      "Murmansk",
      "Nenets",
      "Nizhnij Novgorod",
      "Novgorod",
      "Novokusnezk",
      "Novosibirsk",
      "Omsk",
      "Orenburg",
      "Orjol",
      "Penza",
      "Perm",
      "Primorje",
      "Pskov",
      "Pskovskaya Oblast",
      "Rjazan",
      "Rostov",
      "Saha",
      "Sahalin",
      "Samara",
      "Samarskaya",
      "Sankt-Peterburg",
      "Saratov",
      "Smolensk",
      "Stavropol",
      "Sverdlovsk",
      "Tajmyrija",
      "Tambov",
      "Tatarstan",
      "Tjumen",
      "Tomsk",
      "Tula",
      "Tver",
      "Tyva",
      "Udmurtija",
      "Uljanovsk",
      "Ulyanovskaya Oblast",
      "Ust-Orda",
      "Vladimir",
      "Volgograd",
      "Vologda",
      "Voronezh"
    ],
    "Rwanda": [
      "Butare",
      "Byumba",
      "Cyangugu",
      "Gikongoro",
      "Gisenyi",
      "Gitarama",
      "Kibungo",
      "Kibuye",
      "Kigali-ngali",
      "Ruhengeri"
    ],
    "Saint Helena": [
      "Ascension",
      "Gough Island",
      "Saint Helena",
      "Tristan da Cunha"
    ],
    "Saint Kitts And Nevis": [
      "Christ Church Nichola Town",
      "Saint Anne Sandy Point",
      "Saint George Basseterre",
      "Saint George Gingerland",
      "Saint James Windward",
      "Saint John Capesterre",
      "Saint John Figtree",
      "Saint Mary Cayon",
      "Saint Paul Capesterre",
      "Saint Paul Charlestown",
      "Saint Peter Basseterre",
      "Saint Thomas Lowland",
      "Saint Thomas Middle Island",
      "Trinity Palmetto Point"
    ],
    "Saint Lucia": [
      "Anse-la-Raye",
      "Canaries",
      "Castries",
      "Choiseul",
      "Dennery",
      "Gros Inlet",
      "Laborie",
      "Micoud",
      "Soufriere",
      "Vieux Fort"
    ],
    "Saint Pierre and Miquelon": [
      "Miquelon-Langlade",
      "Saint-Pierre"
    ],
    "Saint Vincent And The Grenadines": [
      "Charlotte",
      "Grenadines",
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint Patrick"
    ],
    "Samoa": [
      "A\\'ana",
      "Aiga-i-le-Tai",
      "Atua",
      "Fa\\'asaleleaga",
      "Gaga\\'emauga",
      "Gagaifomauga",
      "Palauli",
      "Satupa\\'itea",
      "Tuamasaga",
      "Va\\'a-o-Fonoti",
      "Vaisigano"
    ],
    "San Marino": [
      "Acquaviva",
      "Borgo Maggiore",
      "Chiesanuova",
      "Domagnano",
      "Faetano",
      "Fiorentino",
      "Montegiardino",
      "San Marino",
      "Serravalle"
    ],
    "Sao Tome and Principe": [
      "Agua Grande",
      "Cantagalo",
      "Lemba",
      "Lobata",
      "Me-Zochi",
      "Pague"
    ],
    "Saudi Arabia": [
      "Al Khobar",
      "Aseer",
      "Ash Sharqiyah",
      "Asir",
      "Central Province",
      "Eastern Province",
      "Ha\\'il",
      "Jawf",
      "Jizan",
      "Makkah",
      "Najran",
      "Qasim",
      "Tabuk",
      "Western Province",
      "al-Bahah",
      "al-Hudud-ash-Shamaliyah",
      "al-Madinah",
      "ar-Riyad"
    ],
    "Senegal": [
      "Dakar",
      "Diourbel",
      "Fatick",
      "Kaolack",
      "Kolda",
      "Louga",
      "Saint-Louis",
      "Tambacounda",
      "Thies",
      "Ziguinchor"
    ],
    "Serbia": [
      "Central Serbia",
      "Kosovo and Metohija",
      "Vojvodina"
    ],
    "Seychelles": [
      "Anse Boileau",
      "Anse Royale",
      "Cascade",
      "Takamaka",
      "Victoria"
    ],
    "Sierra Leone": [
      "Eastern",
      "Northern",
      "Southern",
      "Western"
    ],
    "Singapore": [
      "Singapore"
    ],
    "Slovakia": [
      "Banskobystricky",
      "Bratislavsky",
      "Kosicky",
      "Nitriansky",
      "Presovsky",
      "Trenciansky",
      "Trnavsky",
      "Zilinsky"
    ],
    "Slovenia": [
      "Benedikt",
      "Gorenjska",
      "Gorishka",
      "Jugovzhodna Slovenija",
      "Koroshka",
      "Notranjsko-krashka",
      "Obalno-krashka",
      "Obcina Domzale",
      "Obcina Vitanje",
      "Osrednjeslovenska",
      "Podravska",
      "Pomurska",
      "Savinjska",
      "Slovenian Littoral",
      "Spodnjeposavska",
      "Zasavska"
    ],
    "Solomon Islands": [
      "Central",
      "Choiseul",
      "Guadalcanal",
      "Isabel",
      "Makira and Ulawa",
      "Malaita",
      "Rennell and Bellona",
      "Temotu",
      "Western"
    ],
    "Somalia": [
      "Awdal",
      "Bakol",
      "Banadir",
      "Bari",
      "Bay",
      "Galgudug",
      "Gedo",
      "Hiran",
      "Jubbada Hose",
      "Jubbadha Dexe",
      "Mudug",
      "Nugal",
      "Sanag",
      "Shabellaha Dhexe",
      "Shabellaha Hose",
      "Togdher",
      "Woqoyi Galbed"
    ],
    "South Africa": [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "Kempton Park",
      "Kramerville",
      "KwaZulu Natal",
      "Limpopo",
      "Mpumalanga",
      "North West",
      "Northern Cape",
      "Parow",
      "Table View",
      "Umtentweni",
      "Western Cape"
    ],
    "South Georgia": [
      "South Georgia"
    ],
    "South Sudan": [
      "Central Equatoria"
    ],
    "Spain": [
      "A Coruna",
      "Alacant",
      "Alava",
      "Albacete",
      "Almeria",
      "Andalucia",
      "Asturias",
      "Avila",
      "Badajoz",
      "Balears",
      "Barcelona",
      "Bertamirans",
      "Biscay",
      "Burgos",
      "Caceres",
      "Cadiz",
      "Cantabria",
      "Castello",
      "Catalunya",
      "Ceuta",
      "Ciudad Real",
      "Comunidad Autonoma de Canarias",
      "Comunidad Autonoma de Cataluna",
      "Comunidad Autonoma de Galicia",
      "Comunidad Autonoma de las Isla",
      "Comunidad Autonoma del Princip",
      "Comunidad Valenciana",
      "Cordoba",
      "Cuenca",
      "Gipuzkoa",
      "Girona",
      "Granada",
      "Guadalajara",
      "Guipuzcoa",
      "Huelva",
      "Huesca",
      "Jaen",
      "La Rioja",
      "Las Palmas",
      "Leon",
      "Lerida",
      "Lleida",
      "Lugo",
      "Madrid",
      "Malaga",
      "Melilla",
      "Murcia",
      "Navarra",
      "Ourense",
      "Pais Vasco",
      "Palencia",
      "Pontevedra",
      "Salamanca",
      "Santa Cruz de Tenerife",
      "Segovia",
      "Sevilla",
      "Soria",
      "Tarragona",
      "Tenerife",
      "Teruel",
      "Toledo",
      "Valencia",
      "Valladolid",
      "Vizcaya",
      "Zamora",
      "Zaragoza"
    ],
    "Sri Lanka": [
      "Amparai",
      "Anuradhapuraya",
      "Badulla",
      "Boralesgamuwa",
      "Colombo",
      "Galla",
      "Gampaha",
      "Hambantota",
      "Kalatura",
      "Kegalla",
      "Kilinochchi",
      "Kurunegala",
      "Madakalpuwa",
      "Maha Nuwara",
      "Malwana",
      "Mannarama",
      "Matale",
      "Matara",
      "Monaragala",
      "Mullaitivu",
      "North Eastern Province",
      "North Western Province",
      "Nuwara Eliya",
      "Polonnaruwa",
      "Puttalama",
      "Ratnapuraya",
      "Southern Province",
      "Tirikunamalaya",
      "Tuscany",
      "Vavuniyawa",
      "Western Province",
      "Yapanaya",
      "kadawatha"
    ],
    "Sudan": [
      "A\\'ali-an-Nil",
      "Bahr-al-Jabal",
      "Central Equatoria",
      "Gharb Bahr-al-Ghazal",
      "Gharb Darfur",
      "Gharb Kurdufan",
      "Gharb-al-Istiwa\\'iyah",
      "Janub Darfur",
      "Janub Kurdufan",
      "Junqali",
      "Kassala",
      "Nahr-an-Nil",
      "Shamal Bahr-al-Ghazal",
      "Shamal Darfur",
      "Shamal Kurdufan",
      "Sharq-al-Istiwa\\'iyah",
      "Sinnar",
      "Warab",
      "Wilayat al Khartum",
      "al-Bahr-al-Ahmar",
      "al-Buhayrat",
      "al-Jazirah",
      "al-Khartum",
      "al-Qadarif",
      "al-Wahdah",
      "an-Nil-al-Abyad",
      "an-Nil-al-Azraq",
      "ash-Shamaliyah"
    ],
    "Suriname": [
      "Brokopondo",
      "Commewijne",
      "Coronie",
      "Marowijne",
      "Nickerie",
      "Para",
      "Paramaribo",
      "Saramacca",
      "Wanica"
    ],
    "Svalbard And Jan Mayen Islands": [
      "Svalbard"
    ],
    "Swaziland": [
      "Hhohho",
      "Lubombo",
      "Manzini",
      "Shiselweni"
    ],
    "Sweden": [
      "Alvsborgs Lan",
      "Angermanland",
      "Blekinge",
      "Bohuslan",
      "Dalarna",
      "Gavleborg",
      "Gaza",
      "Gotland",
      "Halland",
      "Jamtland",
      "Jonkoping",
      "Kalmar",
      "Kristianstads",
      "Kronoberg",
      "Norrbotten",
      "Orebro",
      "Ostergotland",
      "Saltsjo-Boo",
      "Skane",
      "Smaland",
      "Sodermanland",
      "Stockholm",
      "Uppsala",
      "Varmland",
      "Vasterbotten",
      "Vastergotland",
      "Vasternorrland",
      "Vastmanland",
      "Vastra Gotaland"
    ],
    "Switzerland": [
      "Aargau",
      "Appenzell Inner-Rhoden",
      "Appenzell-Ausser Rhoden",
      "Basel-Landschaft",
      "Basel-Stadt",
      "Bern",
      "Canton Ticino",
      "Fribourg",
      "Geneve",
      "Glarus",
      "Graubunden",
      "Heerbrugg",
      "Jura",
      "Kanton Aargau",
      "Luzern",
      "Morbio Inferiore",
      "Muhen",
      "Neuchatel",
      "Nidwalden",
      "Obwalden",
      "Sankt Gallen",
      "Schaffhausen",
      "Schwyz",
      "Solothurn",
      "Thurgau",
      "Ticino",
      "Uri",
      "Valais",
      "Vaud",
      "Vauffelin",
      "Zug",
      "Zurich"
    ],
    "Syria": [
      "Aleppo",
      "Dar\\'a",
      "Dayr-az-Zawr",
      "Dimashq",
      "Halab",
      "Hamah",
      "Hims",
      "Idlib",
      "Madinat Dimashq",
      "Tartus",
      "al-Hasakah",
      "al-Ladhiqiyah",
      "al-Qunaytirah",
      "ar-Raqqah",
      "as-Suwayda"
    ],
    "Taiwan": [
      "Changhwa",
      "Chiayi Hsien",
      "Chiayi Shih",
      "Eastern Taipei",
      "Hsinchu Hsien",
      "Hsinchu Shih",
      "Hualien",
      "Ilan",
      "Kaohsiung Hsien",
      "Kaohsiung Shih",
      "Keelung Shih",
      "Kinmen",
      "Miaoli",
      "Nantou",
      "Northern Taiwan",
      "Penghu",
      "Pingtung",
      "Taichung",
      "Taichung Hsien",
      "Taichung Shih",
      "Tainan Hsien",
      "Tainan Shih",
      "Taipei Hsien",
      "Taipei Shih / Taipei Hsien",
      "Taitung",
      "Taoyuan",
      "Yilan",
      "Yun-Lin Hsien",
      "Yunlin"
    ],
    "Tajikistan": [
      "Dushanbe",
      "Gorno-Badakhshan",
      "Karotegin",
      "Khatlon",
      "Sughd"
    ],
    "Tanzania": [
      "Arusha",
      "Dar es Salaam",
      "Dodoma",
      "Iringa",
      "Kagera",
      "Kigoma",
      "Kilimanjaro",
      "Lindi",
      "Mara",
      "Mbeya",
      "Morogoro",
      "Mtwara",
      "Mwanza",
      "Pwani",
      "Rukwa",
      "Ruvuma",
      "Shinyanga",
      "Singida",
      "Tabora",
      "Tanga",
      "Zanzibar and Pemba"
    ],
    "Thailand": [
      "Amnat Charoen",
      "Ang Thong",
      "Bangkok",
      "Buri Ram",
      "Chachoengsao",
      "Chai Nat",
      "Chaiyaphum",
      "Changwat Chaiyaphum",
      "Chanthaburi",
      "Chiang Mai",
      "Chiang Rai",
      "Chon Buri",
      "Chumphon",
      "Kalasin",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Khon Kaen",
      "Krabi",
      "Krung Thep",
      "Lampang",
      "Lamphun",
      "Loei",
      "Lop Buri",
      "Mae Hong Son",
      "Maha Sarakham",
      "Mukdahan",
      "Nakhon Nayok",
      "Nakhon Pathom",
      "Nakhon Phanom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nan",
      "Narathiwat",
      "Nong Bua Lam Phu",
      "Nong Khai",
      "Nonthaburi",
      "Pathum Thani",
      "Pattani",
      "Phangnga",
      "Phatthalung",
      "Phayao",
      "Phetchabun",
      "Phetchaburi",
      "Phichit",
      "Phitsanulok",
      "Phra Nakhon Si Ayutthaya",
      "Phrae",
      "Phuket",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Ranong",
      "Ratchaburi",
      "Rayong",
      "Roi Et",
      "Sa Kaeo",
      "Sakon Nakhon",
      "Samut Prakan",
      "Samut Sakhon",
      "Samut Songkhran",
      "Saraburi",
      "Satun",
      "Si Sa Ket",
      "Sing Buri",
      "Songkhla",
      "Sukhothai",
      "Suphan Buri",
      "Surat Thani",
      "Surin",
      "Tak",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Yala",
      "Yasothon"
    ],
    "Togo": [
      "Centre",
      "Kara",
      "Maritime",
      "Plateaux",
      "Savanes"
    ],
    "Tokelau": [
      "Atafu",
      "Fakaofo",
      "Nukunonu"
    ],
    "Tonga": [
      "Eua",
      "Ha\\'apai",
      "Niuas",
      "Tongatapu",
      "Vava\\'u"
    ],
    "Trinidad And Tobago": [
      "Arima-Tunapuna-Piarco",
      "Caroni",
      "Chaguanas",
      "Couva-Tabaquite-Talparo",
      "Diego Martin",
      "Glencoe",
      "Penal Debe",
      "Point Fortin",
      "Port of Spain",
      "Princes Town",
      "Saint George",
      "San Fernando",
      "San Juan",
      "Sangre Grande",
      "Siparia",
      "Tobago"
    ],
    "Tunisia": [
      "Aryanah",
      "Bajah",
      "Bin \\'Arus",
      "Binzart",
      "Gouvernorat de Ariana",
      "Gouvernorat de Nabeul",
      "Gouvernorat de Sousse",
      "Hammamet Yasmine",
      "Jundubah",
      "Madaniyin",
      "Manubah",
      "Monastir",
      "Nabul",
      "Qabis",
      "Qafsah",
      "Qibili",
      "Safaqis",
      "Sfax",
      "Sidi Bu Zayd",
      "Silyanah",
      "Susah",
      "Tatawin",
      "Tawzar",
      "Tunis",
      "Zaghwan",
      "al-Kaf",
      "al-Mahdiyah",
      "al-Munastir",
      "al-Qasrayn",
      "al-Qayrawan"
    ],
    "Turkey": [
      "Adana",
      "Adiyaman",
      "Afyon",
      "Agri",
      "Aksaray",
      "Amasya",
      "Ankara",
      "Antalya",
      "Ardahan",
      "Artvin",
      "Aydin",
      "Balikesir",
      "Bartin",
      "Batman",
      "Bayburt",
      "Bilecik",
      "Bingol",
      "Bitlis",
      "Bolu",
      "Burdur",
      "Bursa",
      "Canakkale",
      "Cankiri",
      "Corum",
      "Denizli",
      "Diyarbakir",
      "Duzce",
      "Edirne",
      "Elazig",
      "Erzincan",
      "Erzurum",
      "Eskisehir",
      "Gaziantep",
      "Giresun",
      "Gumushane",
      "Hakkari",
      "Hatay",
      "Icel",
      "Igdir",
      "Isparta",
      "Istanbul",
      "Izmir",
      "Kahramanmaras",
      "Karabuk",
      "Karaman",
      "Kars",
      "Karsiyaka",
      "Kastamonu",
      "Kayseri",
      "Kilis",
      "Kirikkale",
      "Kirklareli",
      "Kirsehir",
      "Kocaeli",
      "Konya",
      "Kutahya",
      "Lefkosa",
      "Malatya",
      "Manisa",
      "Mardin",
      "Mugla",
      "Mus",
      "Nevsehir",
      "Nigde",
      "Ordu",
      "Osmaniye",
      "Rize",
      "Sakarya",
      "Samsun",
      "Sanliurfa",
      "Siirt",
      "Sinop",
      "Sirnak",
      "Sivas",
      "Tekirdag",
      "Tokat",
      "Trabzon",
      "Tunceli",
      "Usak",
      "Van",
      "Yalova",
      "Yozgat",
      "Zonguldak"
    ],
    "Turkmenistan": [
      "Ahal",
      "Asgabat",
      "Balkan",
      "Dasoguz",
      "Lebap",
      "Mari"
    ],
    "Turks And Caicos Islands": [
      "Grand Turk",
      "South Caicos and East Caicos"
    ],
    "Tuvalu": [
      "Funafuti",
      "Nanumanga",
      "Nanumea",
      "Niutao",
      "Nui",
      "Nukufetau",
      "Nukulaelae",
      "Vaitupu"
    ],
    "Uganda": [
      "Central",
      "Eastern",
      "Northern",
      "Western"
    ],
    "Ukraine": [
      "Cherkas\\'ka",
      "Chernihivs\\'ka",
      "Chernivets\\'ka",
      "Crimea",
      "Dnipropetrovska",
      "Donets\\'ka",
      "Ivano-Frankivs\\'ka",
      "Kharkiv",
      "Kharkov",
      "Khersonska",
      "Khmel\\'nyts\\'ka",
      "Kirovohrad",
      "Krym",
      "Kyyiv",
      "Kyyivs\\'ka",
      "L\\'vivs\\'ka",
      "Luhans\\'ka",
      "Mykolayivs\\'ka",
      "Odes\\'ka",
      "Odessa",
      "Poltavs\\'ka",
      "Rivnens\\'ka",
      "Sevastopol\\'",
      "Sums\\'ka",
      "Ternopil\\'s\\'ka",
      "Volyns\\'ka",
      "Vynnyts\\'ka",
      "Zakarpats\\'ka",
      "Zaporizhia",
      "Zhytomyrs\\'ka"
    ],
    "United Arab Emirates": [
      "Abu Zabi",
      "Ajman",
      "Dubai",
      "Ras al-Khaymah",
      "Sharjah",
      "Sharjha",
      "Umm al Qaywayn",
      "al-Fujayrah",
      "ash-Shariqah"
    ],
    "United Kingdom": [
      "Aberdeen",
      "Aberdeenshire",
      "Argyll",
      "Armagh",
      "Bedfordshire",
      "Belfast",
      "Berkshire",
      "Birmingham",
      "Brechin",
      "Bridgnorth",
      "Bristol",
      "Buckinghamshire",
      "Cambridge",
      "Cambridgeshire",
      "Channel Islands",
      "Cheshire",
      "Cleveland",
      "Co Fermanagh",
      "Conwy",
      "Cornwall",
      "Coventry",
      "Craven Arms",
      "Cumbria",
      "Denbighshire",
      "Derby",
      "Derbyshire",
      "Devon",
      "Dial Code Dungannon",
      "Didcot",
      "Dorset",
      "Dunbartonshire",
      "Durham",
      "East Dunbartonshire",
      "East Lothian",
      "East Midlands",
      "East Sussex",
      "East Yorkshire",
      "England",
      "Essex",
      "Fermanagh",
      "Fife",
      "Flintshire",
      "Fulham",
      "Gainsborough",
      "Glocestershire",
      "Gwent",
      "Hampshire",
      "Hants",
      "Herefordshire",
      "Hertfordshire",
      "Ireland",
      "Isle Of Man",
      "Isle of Wight",
      "Kenford",
      "Kent",
      "Kilmarnock",
      "Lanarkshire",
      "Lancashire",
      "Leicestershire",
      "Lincolnshire",
      "Llanymynech",
      "London",
      "Ludlow",
      "Manchester",
      "Mayfair",
      "Merseyside",
      "Mid Glamorgan",
      "Middlesex",
      "Mildenhall",
      "Monmouthshire",
      "Newton Stewart",
      "Norfolk",
      "North Humberside",
      "North Yorkshire",
      "Northamptonshire",
      "Northants",
      "Northern Ireland",
      "Northumberland",
      "Nottinghamshire",
      "Oxford",
      "Powys",
      "Roos-shire",
      "SUSSEX",
      "Sark",
      "Scotland",
      "Scottish Borders",
      "Shropshire",
      "Somerset",
      "South Glamorgan",
      "South Wales",
      "South Yorkshire",
      "Southwell",
      "Staffordshire",
      "Strabane",
      "Suffolk",
      "Surrey",
      "Sussex",
      "Twickenham",
      "Tyne and Wear",
      "Tyrone",
      "Utah",
      "Wales",
      "Warwickshire",
      "West Lothian",
      "West Midlands",
      "West Sussex",
      "West Yorkshire",
      "Whissendine",
      "Wiltshire",
      "Wokingham",
      "Worcestershire",
      "Wrexham",
      "Wurttemberg",
      "Yorkshire"
    ],
    "United States": [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "Byram",
      "California",
      "Cokato",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Lowa",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Medfield",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Jersy",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Ontario",
      "Oregon",
      "Pennsylvania",
      "Ramey",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Sublimity",
      "Tennessee",
      "Texas",
      "Trimble",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming"
    ],
    "United States Minor Outlying Islands": [
      "United States Minor Outlying I"
    ],
    "Uruguay": [
      "Artigas",
      "Canelones",
      "Cerro Largo",
      "Colonia",
      "Durazno",
      "FLorida",
      "Flores",
      "Lavalleja",
      "Maldonado",
      "Montevideo",
      "Paysandu",
      "Rio Negro",
      "Rivera",
      "Rocha",
      "Salto",
      "San Jose",
      "Soriano",
      "Tacuarembo",
      "Treinta y Tres"
    ],
    "Uzbekistan": [
      "Andijon",
      "Buhoro",
      "Buxoro Viloyati",
      "Cizah",
      "Fargona",
      "Horazm",
      "Kaskadar",
      "Korakalpogiston",
      "Namangan",
      "Navoi",
      "Samarkand",
      "Sirdare",
      "Surhondar",
      "Toskent"
    ],
    "Vanuatu": [
      "Malampa",
      "Penama",
      "Sanma",
      "Shefa",
      "Tafea",
      "Torba"
    ],
    "Vatican City State (Holy See)": [
      "Vatican City State (Holy See)"
    ],
    "Venezuela": [
      "Amazonas",
      "Anzoategui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bolivar",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Distrito Federal",
      "Falcon",
      "Guarico",
      "Lara",
      "Merida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "Tachira",
      "Trujillo",
      "Vargas",
      "Yaracuy",
      "Zulia"
    ],
    "Vietnam": [
      "Bac Giang",
      "Binh Dinh",
      "Binh Duong",
      "Da Nang",
      "Dong Bang Song Cuu Long",
      "Dong Bang Song Hong",
      "Dong Nai",
      "Dong Nam Bo",
      "Duyen Hai Mien Trung",
      "Hanoi",
      "Hung Yen",
      "Khu Bon Cu",
      "Long An",
      "Mien Nui Va Trung Du",
      "Thai Nguyen",
      "Thanh Pho Ho Chi Minh",
      "Thu Do Ha Noi",
      "Tinh Can Tho",
      "Tinh Da Nang",
      "Tinh Gia Lai"
    ],
    "Virgin Islands (British)": [
      "Anegada",
      "Jost van Dyke",
      "Tortola"
    ],
    "Virgin Islands (US)": [
      "Saint Croix",
      "Saint John",
      "Saint Thomas"
    ],
    "Wallis And Futuna Islands": [
      "Alo",
      "Singave",
      "Wallis"
    ],
    "Western Sahara": [
      "Bu Jaydur",
      "Wad-adh-Dhahab",
      "al-\\'Ayun",
      "as-Samarah"
    ],
    "Yemen": [
      "\\'Adan",
      "Abyan",
      "Dhamar",
      "Hadramaut",
      "Hajjah",
      "Hudaydah",
      "Ibb",
      "Lahij",
      "Ma\\'rib",
      "Madinat San\\'a",
      "Sa\\'dah",
      "Sana",
      "Shabwah",
      "Ta\\'izz",
      "al-Bayda",
      "al-Hudaydah",
      "al-Jawf",
      "al-Mahrah",
      "al-Mahwit"
    ],
    "Zambia": [
      "Central",
      "Copperbelt",
      "Eastern",
      "Luapala",
      "Lusaka",
      "North-Western",
      "Northern",
      "Southern",
      "Western"
    ],
    "Zimbabwe": [
      "Bulawayo",
      "Harare",
      "Manicaland",
      "Mashonaland Central",
      "Mashonaland East",
      "Mashonaland West",
      "Masvingo",
      "Matabeleland North",
      "Matabeleland South",
      "Midlands"
    ]
  }