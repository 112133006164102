import React, { Component } from "react";
import Layout from "../../layout";
import './pages.scss'
export default class Terms extends Component {
  state={
    text : ""
  }
  componentDidMount() {
    this.getTerms();
  }
  getTerms() {
    this.props.axios.get('terms/').then(res => {
      if (res.data.status.code === 200) {
        this.setState({text:res.data.data.text})
      }
    })
  }
  render() {
    return (
      <Layout {...this.props} layout="shrink">
        <div className="static_pages">
          <div className="container">
            <h1 className="page_title">Terms &amp; Conditions</h1>
            <div dangerouslySetInnerHTML={{ __html: this.state.text }}>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
