import React, { Component } from "react";
import Layout from '../layout'
export default class EmailVerify extends Component {
    state = {
        text: "",
        is_verify: "",
    }
    componentDidMount() {
        if (this.props.match.params.token) {
            this.verifyEmail();
        } else {
            this.setState({ is_verify: 'signup' })
        }
        (function () {
            window.scrollTo(0, 0)
          })()
    }

    verifyEmail() {
        var formdata = new FormData();
        formdata.append("token", this.props.match.params.token);
        this.props.axios.post("email-verify/", formdata,{is_toggle_error:false}).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ is_verify: 'yes' })
            } else {
                this.setState({ is_verify: 'no' })
            }
        })
        .catch(error=>{
            this.setState({ is_verify: 'no' })
        })
    }

    render() {
        return (
            <Layout {...this.props} layout="shrink">
                <div className="static_pages">
                    <div className="container" style={{ textAlign: "center" }}>
                        {(this.props.match.params.token && this.state.is_verify === "yes") &&
                            <div className="success_submit_box">
                                {/* <img src="/images/check.svg" alt="" /> */}
                                <>
                                    <h2>Email Verify Successfully</h2>
                                    {/* <p >We have send a email verification link on email ,so please verify you email to log in into app.
                                </p> */}
                                    <button className="claimed_button" onClick={(e) =>{this.props.handleProfile("",true); this.props.history.push('/login')}} style={{ width: 264, margin: 5 }}>Back to Login</button>
                                </>

                            </div>
                        }
                        {(this.props.match.params.token && this.state.is_verify === "no") &&
                            <div className="success_submit_box">
                                {/* <img src="/images/check.svg" alt="" /> */}
                                <>
                                    <h2>Email Verification Failed</h2>
                                    <p>This link has been already used or it is expired!</p>
                                   <button className="claimed_button" onClick={(e) => this.props.history.push('/login')} style={{ width: 264, margin: 5 }}>Back to Login</button>
                                </>

                            </div>
                        }
                        {(this.state.is_verify === "signup") &&
                            <div className="success_submit_box">
                            {/* <img src="/images/check.svg" alt="" /> */}
                            <>
                                <h2>Registration Successfully</h2>
                                <p >We have sent you a verification link on the registered email, please verify the email to login.
                            </p>
                                <button className="claimed_button" onClick={(e) => this.props.history.push('/')} style={{ width: 264, margin: 5 }}>Back to Home</button>
                            </>

                        </div>
                        }
                        {(this.state.is_verify === "") &&
                            <div className="success_submit_box">
                                {/* <img src="/images/check.svg" alt="" /> */}
                                <>
                                    <h2>Email Verification</h2>
                                    <p>Email verification is in progress please wait a moment.</p>
                                </>

                            </div>
                        }
                    </div>
                </div>
            </Layout>
        );
    }
}
